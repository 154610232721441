export const modelCreatorStepDatas = [
	{
		step: ["Type"],
		stepName: "step 1",
		title: "Choose a Model Type",
		subtitle: null
	},
	{
		step: ["Detection", "Extractor", "Classifier"],
		stepName: "step 2",
		title: "Import your text data",
		subtitle: "Upload a CSV or Excel file with your text data"
	}
];

export const modelCreatorStepDatasUploader = [
	{
		step: ['Uploader'],
		stepName: "step 1",
	},
	{
		step: ["Preview"],
		stepName: "step 2",
		title: "Select Texts",
		subtitle: "Select the columns with yout texts. Multiple seleted columns will be concatenated."
	},
	{
		step: ["Tags"],
		stepName: "step 3",
		title: "Defined Tags",
		subtitle: "Your text data will be extracted into the tags you define, you need to create at least two, but you can define more later.",
	},
	{
		step: ["Custom"],
		stepName: "step 4",
		title: "Manage your dataset",
		subtitle: ""
	},
	{
		step: ["Name"],
		stepName: "step 5",
		title: "Choose a Model Name",
		subtitle: null
	},
];


export const modelCreatorStepDatasCustom = [
	{
		step: ["Tags"],
		stepName: "step 1",
		title: "Defined Tags",
		subtitle: "Your text data will be extracted into the tags you define, you need to create at least two, but you can define more later.",
	},
	{
		step: ["Custom"],
		stepName: "step 2",
		title: "Create your dataset manually",
		subtitle: ""
	},
	{
		step: ["Name"],
		stepName: "step 3",
		title: "Choose a Model Name",
		subtitle: null
	},
];