import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledSeparator = styled.div`
    position: relative;
    padding: 12px;
    background-color: transparent;

    width: ${props => props.type === 'horizontal' ? '100%' : 'auto'};

    &:before{
        content: "";
        position: absolute;
        top: ${props => props.type === 'vertical' ? '0px' : '50%'};
        bottom: ${props => props.type === 'vertical' ? '0px' : 'auto'};
        left: ${props => props.type === 'vertical' ? '50%' : '0px'};
        right: ${props => props.type === 'vertical' ? '50%' : '0px'};
        height: ${props => props.type === 'vertical' ? 'auto' : '1px'};
        width: ${props => props.type === 'vertical' ? '1px' : 'auto'};
        background-color: ${props => props.lineColor};
    }
    
    span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        align-items: center;
        justify-content: center;

        width: 30px;
        height: 30px;

        border: 1px solid ${props => props.theme.black4};
        border-radius: 50%;

        font-size: 18px;
        background-color: #fff;

        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

    }

    svg {
        color: ${props => props.color};
    }
`;

const Separator = ({direction, type, color, lineColor, ...rest}) => {
    let icon =`long-arrow-${direction}`;
    return (<StyledSeparator direction={direction} type={type} color={color} lineColor={lineColor} {...rest} >
            {
                direction && <span><FontAwesomeIcon icon={['fas', icon]}/></span>
            }
        </StyledSeparator>)
};

Separator.defaultProps = {
    type: "vertical",
    direction: null,
    color: "#475cff",
    lineColor: "#bfcad8"
}

export default Separator;