import {DynamicModelBreadcrumb} from './components/Breadcrumbs/DynamicBreadcrumbs';

import Login from './Auth/Login';
import Logout from './Auth/Logout';

import ModelExplorer from './views/ModelExplorer';
import ModelDetail from './views/ModelDetail';
import ModelCreator from './views/ModelCreator';

import SpiderList from './views/Spider-List';
import SpiderAdd from './views/Spider-Add';
import SpiderDetail from './views/Spider-Detail';
import SpiderDetailStandalone from './views/Spider-Detail-Standalone';

export default [
  { path: "/login", Component: Login, unauthenticated: true },
  { path: "/logout", Component: Logout, authenticated: false },
  { path: "/Spider/New/", Component: SpiderAdd },
  { path: "/Spider/List/", Component: SpiderList },
  { path: "/Standalone/Spider/:siteId/Detail/", Component: SpiderDetailStandalone, unauthenticated: true},
  { path: "/Spider/:siteId/Detail/", Component: SpiderDetail},
  { path: "/Spider/:siteId/", Component: SpiderDetail, breadcrumb: null },
  { path: "/Spider/", Component: SpiderList },
  { path: "/ModelCreator/Train/", Component: ModelCreator, componentProps: {section: "Train"} },
  { path: "/ModelCreator/Tags/", Component: ModelCreator, componentProps: {section: "Tags"} },
  { path: "/ModelCreator/Detection/", Component: ModelCreator, componentProps: {section: "Detection"} },
  { path: "/ModelCreator/Extractor/", Component: ModelCreator, componentProps: {section: "Extractor"} },
  { path: "/ModelCreator/Classifier/", Component: ModelCreator, componentProps: {section: "Classifier"} },
  { path: "/ModelCreator/Preview/", Component: ModelCreator, componentProps: {section: "Preview"} },
  { path: "/ModelCreator/Uploader/", Component: ModelCreator, componentProps: {section: "Uploader"} },
  { path: "/ModelCreator/Custom/", Component: ModelCreator, componentProps: {section: "Custom"} },
  { path: "/ModelCreator/Type/", Component: ModelCreator, componentProps: {section: "Type"} },
  { path: "/ModelCreator/Name/", Component: ModelCreator, componentProps: {section: "Name"} },
  { path: "/ModelCreator/", Component: ModelCreator, componentProps: {section: "Type"} },
  { path: "/:modelType/:modelId/Revise/", Component: ModelDetail, componentProps: {tab: "Revise"} },
  { path: "/:modelType/:modelId/Teach/", Component: ModelDetail, componentProps: {tab: "Teach"} },
  { path: "/:modelType/:modelId/Stats/", Component: ModelDetail, componentProps: {tab: "Stats"} },
  { path: "/:modelType/:modelId/Test/", Component: ModelDetail, componentProps: {tab: "Test"} },
  { path: "/:modelType/:modelId/API/", Component: ModelDetail, componentProps: {tab: "API"} },
  { path: "/:modelType/:modelId/", Component: ModelDetail, componentProps: {tab: "Test"} , breadcrumb: DynamicModelBreadcrumb},
  { path: "/:modelType/", Component: ModelExplorer },
  { path: "/", Component: ModelExplorer, breadcrumb: "Home" }
];