import React from 'react';
import { observer, inject} from 'mobx-react';
import styled from 'styled-components';

import Layout from '../layout/layout';
import {Header as PageHeader, Wrapper as PageWrapper} from '../components/Page';
import Loader from '../components/Loader/Loader';
import ModelCreatorType from './ModelCreator-Type';
import ModelCreatorUploader from './ModelCreator-Uploader';
import ModelCreatorDataSource from './ModelCreator-DataSource';
import ModelCreatorPreview from './ModelCreator-Preview';
import ModelCreatorTags from './ModelCreator-Tags';
import ModelCreatorCustom from './ModelCreator-Custom';
import ModelCreatorName from './ModelCreator-Name';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

export const StyledCreatorWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const StyledTitle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 48px;

	h2{
		font-size: 36px;
	}

	.surtitle{
		font-family: "Avenir Next Semi";
		color: ${props => props.theme.blue1};
	}

	.subtitle{
		font-family: "Avenir Next Demi Bold";
		font-size: 16px;
		color: ${props => props.theme.black2};
		text-transform: none;
		max-width: 800px;
		text-align: center;
	}
`

const StyledStepper = styled(Stepper)`
	width: 100%;
    background-color: transparent !important;
`;

export const ModelCreatorWrapper = ({children, title = null, surtitle = null, subtitle = null}) => {
	return <PageWrapper>
		{ 
			title && <PageHeader 
				title={title}
				surtitle={surtitle}
				subtitle={subtitle}
			/>
		}
		{ children }
	</PageWrapper>
};

const ModelCreatorWrapperWithStepper = ({children, title = null, surtitle = null, subtitle = null, activeStep = 0, modelCreatorStepDatas}) => {
	return <PageWrapper>
	 	{
			activeStep >= 2 &&<StyledStepper activeStep={activeStep - 2} alternativeLabel style={{marginTop: '-40px'}}>
		        {modelCreatorStepDatas.map(({stepName}) => (
		          <Step key={stepName}>
		            <StepLabel>{stepName}</StepLabel>
		          </Step>
		        ))}
	     	</StyledStepper>
		}
		{ 
			title && <PageHeader 
				title={title}
				surtitle={surtitle}
				subtitle={subtitle}
			/>
		}
		{ children }
	</PageWrapper>
};

const ModelCreator = inject('ModelCreatorStore', 'ModelsStore')(
	observer(({ModelCreatorStore, ModelsStore, section, match, ...props}) => {
		let stepDatas = ModelCreatorStore.getStepDatas(section);

		return <Layout match={match}>
			
			{
				/*
				<StyledProgressBar
					percent={stepDatas.avancement}
					rounded={false}
					height={4}
				/>
				*/
			}

	        { !ModelsStore.loading && <>
	        	<ModelCreatorWrapperWithStepper
	        		{...stepDatas}
	        		activeStep={ModelCreatorStore.getCurrentStep(section)}
	        		modelCreatorStepDatas={ModelCreatorStore.getStepsDatas()}
        		>
		    		{ section === "Name" && <ModelCreatorName /> }
		    		{ section === "Tags" && <ModelCreatorTags /> }
		    		{ section === "Preview" && <ModelCreatorPreview /> }
		    		{ section === "Type" && <ModelCreatorType /> }
		    		{ (section === "Classifier" || section === "Extractor" || section === "Detection") && <ModelCreatorDataSource /> }
		    		{ section === "Uploader" && <ModelCreatorUploader /> }
		    		{ section === "Custom" && <ModelCreatorCustom /> }
	        	</ModelCreatorWrapperWithStepper>
			</>}
			{ ModelsStore.loading && <Loader /> }
		</Layout>
	})
);

export default ModelCreator;