import React from 'react';
import clsx from 'clsx';
import { observer, inject} from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import Menu from './menu';
import Header from './header';

const useStyles = makeStyles((theme) => ({
  mainContent: {
  	position: 'relative',
  	display: 'flex',
  	flexDirection: 'column',
  	minHeight: '100vh',
  	marginLeft: 'auto',
  	marginRight: 0,
    padding: '12px',
    width: props => `100%`,
    [theme.breakpoints.up('md')]: {
  	   	width: props => props.standalone ? '100%' : `calc(100% - ${props.drawerCloseWidth}px)`,
    	padding: '12px 24px',
    }
  },
  innerContainer:{
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center',
	  width: '100%',
	  margin: '50px 0',
	  padding: '0 12px',
	  flex: 1
  },
  innerContainerHeaderFixed: {
  	marginTop: props => props.standalone ? 50 : 120
  }
}));

const Layout = inject('UiStore')(
	observer(({children, UiStore, innerStyle = {}, standalone = false, ...props}) => {

		const classes = useStyles({
			drawerWidth: UiStore.drawerWidth,
			drawerCloseWidth: UiStore.drawerCloseWidth,
			standalone: standalone
		});

		return <>
			{!standalone && <Menu />}
			<div 
				className={clsx(classes.mainContent, UiStore.contentPushBySidebar ? {
	    			[classes.drawerOpen]: UiStore.menuOpen,
	    			[classes.drawerClose]: !UiStore.menuOpen,
	    			"standalone": standalone
	    		} : {})}
			>
		        {!standalone && <Header />}
		        <div style={{...innerStyle}} className={clsx(classes.innerContainer, UiStore.headerFixed && classes.innerContainerHeaderFixed)}>
					{children}
				</div>
			</div>
		</>
	})
)

export default Layout;