import React from 'react';
import { useParams } from "react-router-dom";
import { observer, inject} from 'mobx-react';
import styled from 'styled-components';
import {Paper} from '../components/Containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleTag from '../components/SimpleTag/SimpleTag';
import Classify from '../components/Classify/Classify';

const StyledModelHeader = styled.div`
	display: flex;
	margin-bottom: 24px;

	& > div{
		display: flex;
		width: 100%;
		padding: 24px 0;
	}
	
	.Model__Header-icon-wrapper{
		padding: 0 12px;
		flex: 1;
		display: flex;
		justify-content: center;
	}

	.Model__Header-icon{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		margin-bottom: 12px;
		font-size: 24px;
	}

	.Model__Header-title{
		flex: 2;
		line-height: normal;
		padding: 0 24px 0 0;

		h2{
			margin-bottom: 4px;
			text-transform: normal;
		}

		.Model__Header-lang{
			color: ${props => props.theme.black1};
			font-size: 14px;
		}
	}

	.Model__Header-content{
		flex: 9;
		color: ${props => props.theme.black1};
		padding: 0 12px;

		.Model__Header-tagsList{
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin-top: 12px;
			margin-bottom: 12px;

			span{
				margin-right: 12px;
				border: none;
				margin-bottom: 8px;
			}
		}
	}
`;

const ModelHeader = ({icon, iconColor, iconBgColor, name, language, children, ...rest}) => <StyledModelHeader>
	<Paper>
			<div className="Model__Header-icon-wrapper">
				{ icon && <span className={`Model__Header-icon`} style={{color: iconColor, backgroundColor: iconBgColor}}>
					<FontAwesomeIcon icon={['fad', icon]} />
				</span> }
			</div>

			<div className="Model__Header-title">
				<h2>{name}</h2>
				<span className="Model__Header-lang">{language}</span>
			</div>

			<div className="Model__Header-content">
				{children}
			</div>
	</Paper>
</StyledModelHeader>;

const ModelDetailTest = inject('ModelsStore')(
	observer(({ModelsStore, ...props}) => {
		let { modelId } = useParams();
		let currentModel = ModelsStore.modelById(modelId);

		return <>
	    	<ModelHeader 
	    		{...currentModel}
    			language={currentModel.language}
			>
				{currentModel.description}
	    		<div className='Model__Header-tagsList'>	
	    			{
	    				currentModel.returned_values.map(returned_value => <SimpleTag 
	    					color="blue"
	    					fullRounded
	    					key={`skill-${returned_value}`}
    					>{returned_value}</SimpleTag>)
    				}		
	    		</div>
	    	</ModelHeader>
	    	<Classify />
    	</>
	})
);

export default ModelDetailTest;