import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.div`
	display: flex;
	align-items: center;
	user-select: none;

	svg{
		width: auto;
		height: 30px;
	}

	.logo-text{
		margin-right: 6px;
	}

	.logo-more{
		color: ${props => props.moreColor};
		font-size: 20px;
		font-family: 'Gilroy';
		font-weight: 600;
		margin-top: -2px;
		margin-left: 6px;
	}
`;



const LogoReecall = ({textColor = "#323B44", iconBgColor = "#465CFF", iconInsideColor = "#FFFFFF", more = null, moreColor = "#465CFF", onlyIcon = false, ...props}) => {
	return <StyledLogo moreColor={moreColor} {...props} >
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" viewBox={onlyIcon ? "0 0 258.6 237.3" : "0 0 811.6 237.3"}>
		  	{!onlyIcon && <path fill={textColor} className="logo-text" d="M342.9 86.6v19.1c-11.6-.8-25.2 4.6-25.2 22.9v41.2h-17.6V88h17.6v13.7c4.9-11 14.9-15.1 25.2-15.1zM393.9 155.7c9.8 0 17.2-4.4 21.1-10.1l14.5 8.5c-7.5 11.1-19.8 17.8-35.8 17.8-27 0-44.4-18.5-44.4-43 0-24.2 17.5-43 43.1-43 24.3 0 41 19.6 41 43.1 0 2.5-.3 5.1-.7 7.4h-65c2.8 12.8 13.1 19.3 26.2 19.3zm21.7-33.7c-2.5-14.1-12.7-20.1-23.4-20.1-13.2 0-22.2 7.8-24.7 20.1h48.1zM490.1 155.7c9.8 0 17.2-4.4 21.1-10.1l14.5 8.5c-7.5 11.1-19.8 17.8-35.8 17.8-27 0-44.4-18.5-44.4-43 0-24.2 17.5-43 43.1-43 24.3 0 41 19.6 41 43.1 0 2.5-.3 5.1-.7 7.4h-65c2.8 12.8 13.1 19.3 26.2 19.3zm21.7-33.7c-2.5-14.1-12.7-20.1-23.4-20.1-13.2 0-22.2 7.8-24.7 20.1h48.1zM541.7 128.9c0-24.3 18.5-43 43.1-43 16 0 29.9 8.3 36.4 21.1l-15.2 8.8c-3.6-7.7-11.6-12.6-21.4-12.6-14.4 0-25.3 10.9-25.3 25.7 0 14.7 10.9 25.7 25.3 25.7 9.8 0 17.8-5.1 21.7-12.6l15.2 8.7c-6.9 12.9-20.7 21.2-36.8 21.2-24.5 0-43-18.8-43-43zM717 88v81.7h-17.6V158c-6.2 8.7-15.8 13.9-28.6 13.9-22.2 0-40.7-18.8-40.7-43 0-24.3 18.5-43 40.7-43 12.7 0 22.4 5.2 28.6 13.7V88H717zm-17.6 40.9c0-15.2-11.1-26.1-25.8-26.1s-25.8 10.9-25.8 26.1 11.1 26.1 25.8 26.1c14.7 0 25.8-10.9 25.8-26.1zM739.2 50.5h17.6v119.3h-17.6V50.5zM778.9 50.5h17.6v119.3h-17.6V50.5z"/>}
		  	<g className="logo-icon">
		  		<path fill={iconBgColor} d="M233.4 67.6C217.4 21.5 88.6-6.6 40.5 38.7c-59 55.6 5.9 183.9 76.5 182.7s131.6-110 116.4-153.8z"/>
				<path fill={iconInsideColor} d="M154,112.8c0-2.1-0.1-4.3-0.1-6.7v-0.3v-0.3c-0.1-0.6-0.2-1.3-0.3-2.1c-0.3-2.3-0.8-5.3-1.4-8.4   c-2.4-12.8-7.6-24.9-15.4-35.8c-2-2.8-4.8-3.7-7.7-2.5c-1.5,0.6-4.7,2.5-3.3,7.4c0.2,0.6,0.4,1,0.6,1.4c0.1,0.1,0.1,0.2,0.2,0.4   c5.9,14.1,9.4,28.3,10.2,42.1c0.6,9.6,0.2,18.1-1.2,26.2c-1.9,10.5-5,21-9.3,31.1c-0.8,1.9-0.9,3.6-0.2,5c0.4,0.9,1.3,2.2,3.2,2.9   c0.7,0.3,1.5,0.5,2.4,0.5c1.7,0,3.6-0.8,5.4-3.2c4.7-6.7,8.5-14,11.6-22.4c2.8-7.7,4.6-15.7,5.3-23.7   C154.1,120.7,154,117,154,112.8z"/>
				<path fill={iconInsideColor} d="M111.6,109.9c-0.4-3-0.9-7.1-1.6-11.1c-1.2-6.4-3.5-12.7-7-19c-1.5-2.7-4.3-3.9-7.4-3.1   c-3.2,0.9-5,3.5-4.5,6.5c0.2,1.3,0.5,2.5,0.8,3.8c0.5,2.4,1,5,1.4,7.4l0.3,2.2c0.6,4.7,1.3,9.5,1.3,14.1c0,5.9-0.4,12.3-1.3,20.2   c-0.4,3.8-1.3,7.6-2.1,11.4c-0.3,1.3-0.5,2.5-0.8,3.8c-0.3,1.6,0,3.2,1.1,4.5c1.1,1.5,3,2.4,5,2.5c0.2,0,0.3,0,0.5,0   c2.8,0,5-1.3,6.3-3.9c5.4-10.3,8-21.5,8.4-36.1v-0.2v-0.2C111.9,112,111.8,111,111.6,109.9z"/>
			</g>
		</svg>
		{more && <span className="logo-more">{more}</span>}
	</StyledLogo>
};

export default LogoReecall;