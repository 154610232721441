import React from 'react';
import { useParams } from "react-router-dom";
import { observer, inject} from 'mobx-react';
import Extractor from '../components/Extractor/Extractor';

const ModelDetailExtractor = inject('ModelsStore')(
	observer(({ModelsStore, ...props}) => {
		let { modelId } = useParams();
		let currentModel = ModelsStore.modelById(modelId);
		return  <Extractor
			tags={currentModel.returned_values}
			onSubmit={(sentence) => {
				console.log(sentence, "sentence");
			}}
		/>
	})
);

export default ModelDetailExtractor;