import React from 'react';
import { observer, inject} from 'mobx-react';
import TextInput from '../components/TextInput/TextInput';
import "../scss/Mixins.scss";

import Button from '@material-ui/core/Button';

const ModelCreatorName = inject('ModelCreatorStore')(
	observer(({ModelCreatorStore, ...props}) => {
		return <>
    		<TextInput 
    			value={ModelCreatorStore.datasetName}
    			onChange={(e) => ModelCreatorStore.datasetName = e.currentTarget.value}
    			style={{maxWidth: "800px", marginBottom: "24px"}}
    			placeholder={"Dataset Name"}
    		/>

			<Button color="primary" variant="contained" size="large">
        		CREATE DATASET
        	</Button>
		</>
	})
);

export default ModelCreatorName;