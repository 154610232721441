import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { observer, inject} from 'mobx-react';
import { Link as RouterLink, useLocation} from 'react-router-dom';
import theme from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import LogoReecall from '../components/Icons/LogoReecall';
import Brain from '../components/Icons/Brain';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
  	drawer: {
	    width: props => props.drawerWidth,
	    flexShrink: 0,
	    whiteSpace: 'nowrap',
	    zIndex: 1201
  	},
  	drawerOpen: {
	   	width: '80%',
	    [theme.breakpoints.up('md')]: {
	    	width: props => props.drawerWidth,
	    },
	    overflowX: 'hidden',
	    transition: theme.transitions.create('width', {
	      easing: theme.transitions.easing.sharp,
	      duration: theme.transitions.duration.enteringScreen,
	    }),
  	},
  	drawerClose: {
	    transition: theme.transitions.create('width', {
	      easing: theme.transitions.easing.sharp,
	      duration: theme.transitions.duration.leavingScreen,
	    }),
	    overflowX: 'hidden',
		width: 0,
	    [theme.breakpoints.up('md')]: {
	    	width: props => props.drawerCloseWidth,
	    },
  	},
  	drawerHeader: {
	    display: 'flex',
	    flexShrink: 0,
	    alignItems: 'center',
	    height: 76,
	    minHeight: 'auto',
	    justifyContent: 'flex-start',
	    paddingLeft: props => `${props.listItemSpace + 4}px`,
	    cursor: 'pointer'
  	},
  	drawerPaper: {
	    border: 0,
	    boxShadow: '0 1px 3px rgba(32,45,113,0.08), 0 1px 2px rgba(32,45,113,0.08)'
  	},
  	drawerContent:{
	  	overflowY: 'scroll',
	  	overflowX: 'hidden'
  	},
  	nested: {
    	paddingLeft: theme.spacing(4),
  	},
  	bottomMenu:{
	  	display: 'flex',
	  	flexDirection: 'column',
	  	alignItems: 'flex-start',
	  	justifyContent: 'flex-start',
	  	paddingBottom: 24,
	  	paddingTop: 24,
	  	backgrouncColor: '#fff'
  	},
  	avatarWrapper: {
	  	display: 'flex',
	  	alignItems: 'center'
  	},
  	avatarName:{
  		fontWeight: 500
  	},
  	listItemText:{
		textTransform: 'capitalize'
  	},
  	DrawerOverlay:{
	    [theme.breakpoints.up('md')]: {
	    	display: 'none'
	    }
  	}
}));

const NestedList = styled(List)({
	padding: '0',
	marginTop: '-6px',
	marginBottom: '6px',
	'&:before': {
		content: "''",
		position: "absolute",
		top: '0px',
		left: "39px",
		width: '1px',
		height: 'calc(100% - 12px)',
		backgroundColor: '#f3f3f3'
	}
})

const MenuRelations = {
	'NLU': null,
	'classifier': 'NLU',
	'detector': 'NLU',
	'extractor': 'NLU',
	"ModelCreator": 'NLU',
	'Spider': null
}

const Menu = inject('UiStore', 'ModelsStore')(
	observer(({UiStore, ModelsStore, ...rest}) => {
		const [selectedMenu, setSelectedMenu] = useState('NLU');
		const [parentMenu, setParentMenu] = useState(null);
  		const { pathname } = useLocation();

	  	useEffect(() => {
		  	let section = pathname.split('/')[1];
		  	if(section === ""){
		  		setSelectedMenu('NLU');
		  	} else {
		  		setSelectedMenu(section);
		  	}
		  	setParentMenu(MenuRelations[section]);
		  	UiStore.closeMenu();
	  	}, [UiStore, pathname]);


		const listItemSpace = (UiStore.drawerCloseWidth - 40) / 2;
		const listItemNestedSpace = (UiStore.drawerCloseWidth - 30) / 2;

		const classes = useStyles({
			drawerWidth: UiStore.drawerWidth,
			drawerCloseWidth: UiStore.drawerCloseWidth,
			listItemSpace: listItemSpace
		});

		const StyledListItem = withStyles({
			root:{
				paddingLeft: `${listItemSpace}px`,
				paddingRight: `${listItemSpace}px`,
				'& .MuiListItemAvatar-root':{
					minWidth: 'auto',
					marginRight: `${listItemSpace}px`,
					color: theme.black,
					'& svg':{
		  			width: "24px !important",
						color: theme.black
					}
				},
				'& .MuiAvatar-colorDefault':{
					backgroundColor: theme.black4
				},
				'&:hover': {
					backgroundColor: 'transparent !important',
					'& .MuiAvatar-colorDefault':{
						backgroundColor: props => theme.blue1,
						'& svg path':{
							fill: '#fff'
						}
					}
				}
			},
			selected:{
				backgroundColor: 'transparent !important',
				'& .MuiAvatar-colorDefault':{
					backgroundColor: props => theme.blue1,
					'& svg path':{
						fill: '#fff'
					}
				}
			}
		})(ListItem);

		const StyledListItemNested = withStyles({
			root:{
				paddingLeft: `${listItemNestedSpace}px`,
				paddingRight: `${listItemNestedSpace}px`,
				'& .MuiListItemAvatar-root':{
					minWidth: 'auto',
					marginRight: `${listItemNestedSpace}px`,
					color: theme.black,
				},
				'& .MuiAvatar-root':{
					width: '30px',
					height: '30px',
					'& svg':{
	  				width: "16px !important",
						color: theme.black
					}
				},
				'& .MuiAvatar-colorDefault':{
					backgroundColor: theme.black4
				},
				'&:hover': {
					'& .MuiAvatar-colorDefault':{
						backgroundColor: props => props.color ? '#f3f3f3' : theme.blue1,
						'& svg path':{
							fill: props => props.color || '#fff'
						}
					}
				}
			},
			selected:{
				backgroundColor: 'transparent !important',
				'& .MuiAvatar-colorDefault':{
					backgroundColor: props => props.color ? '#f3f3f3' : theme.blue1,
					'& svg path':{
						fill: props => props.color || '#fff'
					}
				}
			}
		})(ListItem);

		const StyledListItemAvatar = styled(ListItemAvatar)({
			'& svg':{
				width: "24px !important"
			}
		});

		function ListItemLink(listItemProps) {
			const { to, children, color, nested } = listItemProps;
			const renderLink = React.useMemo(
				() =>
					React.forwardRef((linkProps, ref) => (
						<RouterLink ref={ref} to={to} {...linkProps} onClick={() => ModelsStore.unselectAllFamilies()} />
					)),
				[to],
			);
	  		return <>
		  		{
	  				nested && <StyledListItemNested component={renderLink} selected={selectedMenu === listItemProps.section || parentMenu === listItemProps.section} color={color}>
		      			{children}
		      		</StyledListItemNested>
	      		}
		      	{
		      		!nested && <StyledListItem component={renderLink} selected={selectedMenu === listItemProps.section || parentMenu === listItemProps.section} color={color}>
			      		{children}
			      	</StyledListItem>
		      	}
	    	</>
		}

		return <>
			<Drawer
		  		variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: UiStore.menuOpen,
						[classes.drawerClose]: !UiStore.menuOpen,
					})}
		  		classes={{
		    		paper: clsx({
		      			[classes.drawerOpen]: UiStore.menuOpen,
		      			[classes.drawerClose]: !UiStore.menuOpen,
		      			[classes.drawerPaper]: true
		    		}),
		  		}}
		  		onMouseEnter={() => UiStore.openMenu()}
		  		onMouseLeave={() => UiStore.closeMenu()}
	  		>
			    
		    	<div className={classes.drawerHeader}	>
		    		<Link component={RouterLink} to={'/'}>
						{
							!UiStore.menuOpen && <LogoReecall onlyIcon/>
				      	}
			      		{
							UiStore.menuOpen && <LogoReecall
								more="brain center"
								moreColor="#ff5451"
								aria-label="open drawer"
							/>
			      		}
		    		</Link>
			    </div>

		    	<Divider />

		    	<div className={`${classes.drawerContent} ${classes.grow}`}	>
			   
				    <List>
			        <ListItemLink button key={"NLU"} to={'/'} section={"NLU"}>
			          	<StyledListItemAvatar>
			          		<Avatar>
								{ 
									//<FontAwesomeIcon icon={['fal', 'brain']} color={theme.black} />
								}
			          			{
			          				<Brain color={theme.black} />
			          			}
			          		</Avatar>
			          	</StyledListItemAvatar>
			          	<ListItemText primary={'NLU'} className={classes.listItemText}/>
			        </ListItemLink>

				    	<NestedList>
				    		{
				    			ModelsStore.families.map(({label, color = null, selected = false, icon = null}) => (
			    				<ListItemLink button key={label} to={`/${label}/`} color={color} section={label} nested={true}>
					          		<StyledListItemAvatar>
						          	{
						          		icon && <Avatar>
											<FontAwesomeIcon icon={['fal', icon]} color={theme.black} />
					          			</Avatar>
						          	}
									</StyledListItemAvatar>
				          			<ListItemText primary={label} className={classes.listItemText}/>
						        </ListItemLink>
				        		))	
			    			}

					        {
					        	/*
					        	<ListItemLink button key={"ModelCreator"} to={'/ModelCreator/'} section={"ModelCreator"} color={theme.orange} nested={true}>
						          	<StyledListItemAvatar>
						          		<Avatar>
											<FontAwesomeIcon icon={['fal', 'plus']} color={theme.black}/>
						          		</Avatar>
						          	</StyledListItemAvatar>
						          	<ListItemText primary={'ModelCreator'} className={classes.listItemText}/>
						        </ListItemLink>
						        */
					        }

				    	</NestedList>
			        <ListItemLink button key={"Spider"} to={'/Spider/'} section={"Spider"}>
			          	<StyledListItemAvatar>
			          		<Avatar>
								<FontAwesomeIcon icon={['fal', 'spider']} color={theme.black}/>
			          		</Avatar>
			          	</StyledListItemAvatar>
			          	<ListItemText primary={'Spider'} className={classes.listItemText}/>
			        </ListItemLink>
				    </List>
		    	</div>

		    	<Divider />
					
				<div className={classes.bottomMenu}>
	      		<Link to={'/'} component={RouterLink}>

						<div className={classes.avatarWrapper} >
							<Avatar 
								style={{
									backgroundColor: theme.blue1,
									marginLeft: `${listItemSpace}px`,
									marginRight: `${listItemSpace}px`
								}}
							>MG</Avatar>

							<Typography
								variant="body1"
								component="span"
								className={classes.avatarName}
							>
								{"Gréard Mathieu"}
							</Typography>
						</div>
					</Link>
				</div>

			</Drawer>
			{
				UiStore.menuOpen && <div 
					className={classes.DrawerOverlay}
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						zIndex: 1200
					}}
					onClick={() => UiStore.closeMenu()}
				/>
			}
		</>
	})
)

export default Menu;