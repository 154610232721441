import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	text-transform: uppercase;

	h2{
		font-size: 36px;
		text-align: center;
	}

	.surtitle{
		font-family: "Avenir Next Semi";
		color: ${props => props.theme.blue1};
	}

	.subtitle{
		font-family: "Avenir Next Demi Bold";
		font-size: 16px;
		color: ${props => props.theme.black2};
		text-transform: none;
		max-width: 800px;
		text-align: center;
	}
`

const StyledHeaderPage = styled.header`
	margin-bottom: 24px;
`;

const Header = ({title = null, surtitle = null, subtitle = null}) => {
	return <StyledHeaderPage>
		<StyledTitle>
			{surtitle && <span className="surtitle">{surtitle}</span>}
			<h2>{title}</h2>
			{subtitle && <p className="subtitle">{subtitle}</p>}
		</StyledTitle>
	</StyledHeaderPage>
};

export default Header;