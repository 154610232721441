import React, {useState} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const StyledTabs = styled.div`
    display: flex;
    /*margin-top: 20px;*/
    width: 100%;

    .Tab-List{
        flex: 1;
        margin-right: 24px;
    }

    .Tab-Content{
        flex: 5;
        /*overflow: hidden;*/
    }
`;

const StyledTab = styled.li`
    a{
        display: flex;
        align-items: center;
        font-weight: 600;
        line-height: normal;
        color: ${props => props.active ? props.theme.blue1 : props.theme.black };
        background-color: ${props => props.active ? "#fff" : 'transparent' };
        padding: 6px 12px;
        margin-bottom: 12px;
        border-radius: 8px;
        cursor: pointer;
        ${props => props.active ? props.theme.shadow(1) : ''};
        user-select: none;

        svg{
            margin-right: 12px;
        }
    }
`;

const Tab = ({label, icon, onClick, to, activeTab}) => <StyledTab 
    onClick={() => onClick(label)} 
    active={activeTab === label}
>
    <Link to={to} >
        { icon && <FontAwesomeIcon icon={icon}/> }
        {label}
    </Link>
</StyledTab>

const Tabs = ({children, tab, ...rest}) => {
    const [activeTab, setActiveTab] = useState(tab);

    return <StyledTabs>
            <ol className="Tab-List">
              { children.filter(child => child).map((child) => {
                const { label, icon, hide = false } = child.props;
                return !hide && <Tab 
                    activeTab={activeTab} 
                    key={label}
                    label={label}
                    onClick={(tab) => setActiveTab(tab)}
                    icon={icon}
                    to={`${label}`}
                />
              })}
            </ol>
            <div className="Tab-Content">
              { children.filter(child => child).map((child) => {
                if (child.props.label !== activeTab || child.props.hide) return undefined;
                return child.props.children;
              })}
            </div>
        </StyledTabs>
};

export default Tabs;