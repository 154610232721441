import {decorate, observable, action, computed} from "mobx";
import qs from 'qs';
const axios = require('axios');

class Tag {
  constructor({label, confidence}) {
    this.label = label;
    this.confidence = confidence;
  }

  get confidenceColor() {
    return this.confidence > 0.7 ? "green": this.confidence > 0.4 ? "yellow" : "red";
  }
}
decorate(
  Tag, {
    label: observable,
    confidence: observable,
    confidenceColor: computed,
})

class ClassifyStore {
    text = "";
    currentTabIndex = 0;
    json = null;
    tags = [];

    setText = (text) => {
      this.text = text;
    }

    clearTags = () => {
      this.tags = [];
    };

    classify = (API_URL, modelId, modelType) => {

      let urlModelType = ({
        detector: 'detector',
        classifier: 'classifier'
      })[modelType];
      console.log(urlModelType, "urlModelType");

      const url = `${API_URL}/${urlModelType}/${modelId}/test`;
      const data = { 'text': this.text };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
      };

      axios(options).then((response) => {
        this.clearTags();
        response.data.map(obj => {
          if(obj.tag !== null){
            this.tags.push(new Tag({label: obj.tag, confidence: obj.confidence}));
          }
          return obj;
        });
        this.json = response.data;
      }).catch((error) => {
        console.log(error);
      });
    } 
}
decorate(ClassifyStore, {
  json: observable,
  currentTabIndex: observable,
  text: observable,
  tags: observable,
  clearTags: action,
  setText: action,
  classify: action
});


export default ClassifyStore;