import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    
    @media (min-width: 768px) {
        max-width: 750px;
    }

    @media (min-width: 992px) {
        max-width: 970px;
    }

    @media (min-width: 1200px) {
        max-width: 1170px;
    }
`

const Wrapper = ({children}) => {
    return <StyledWrapper>
        { children }
    </StyledWrapper>
};

export default Wrapper;