import React from 'react';
import { observer, inject} from 'mobx-react';
import { Card } from '../components/Containers';
import Button from '../components/Button/Button';
import styled from 'styled-components';
import modelFamilyDatas from '../constants/modelFamilyDatas';

const StyledCardList = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: center;
`

const StyledCard = styled(Card)`
	display: flex;
	text-align: center;
	margin: 15px;
	max-width: 260px;

	& > a > div{
		display: flex;
		flex-direction: column;
		margin-top: 0;
		margin-bottom: 0;
	}

	&:hover{
		border: 3px solid ${props => props.theme.blue1};
		border-radius: 6px;
		margin: 12px;

		button{
			background-color: ${props => props.theme.blue2};
		}
	}

	.card-icon{
		margin-top: 24px;
		margin-bottom: 24px;
		margin-left: auto;
		margin-right: auto;
		width: 60px;
		height: 60px;
	}

	h4{
		font-size: 28px;
	}

	.card-inner-wrapper{
		margin-top: auto;
		margin-bottom: 0;
	}

	.family-description{
		max-width: 200px;
		font-size: 16px;
		line-height: normal;
		margin-bottom: 12px;
		margin-left: auto;
		margin-right: auto;
	}

	button{
		margin-top: 24px;
		margin-bottom: 24px;

		&:hover{
			text-decoration: none;
		}
	}
`

const ModelCreatorType = inject('ModelCreatorStore')(
	observer(({ModelCreatorStore, ...props}) => {

		const classifierData = modelFamilyDatas.find(family => family.title === "classifier");
		const extractorData = modelFamilyDatas.find(family => family.title === "extractor");
		const detectionData = modelFamilyDatas.find(family => family.title === "detector");

		return <StyledCardList>
			<StyledCard {...classifierData} to={'/ModelCreator/Classifier/'} onClick={() =>  ModelCreatorStore.setType('classifier')}>
				<div className="family-description">
					{classifierData.description}
				</div>
				<Button>Create Classifier</Button>
			</StyledCard>
			<StyledCard {...extractorData}  to={'/ModelCreator/Extractor/'} onClick={() => ModelCreatorStore.setType('extractor')}>
				<div className="family-description">
					{extractorData.description}
				</div>
				<Button>Create Extractor</Button>
			</StyledCard>
			<StyledCard {...detectionData}  to={'/ModelCreator/Detector/'} onClick={() =>  ModelCreatorStore.setType('detector')}>
				<div className="family-description">
					{detectionData.description}
				</div>
				<Button>Create Detection</Button>
			</StyledCard>
		</StyledCardList>
	})
);

export default ModelCreatorType;