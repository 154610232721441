import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import theme from '../../theme';
import Paper from './Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const StyledCard = styled.div`

	color: ${props => props.theme.black1};
	font-weight: normal;

	& > a{
	    display: block;
	    height: 100%;
	    width: 100%;
	}

	& > a > div{
		padding: 24px;
	}

	h4{
		color: ${props => props.theme.black};
		margin-bottom: 16px;
	}

	.card-icon{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		margin-bottom: 12px;
		font-size: 24px;
	}
`;

const Card = ({children, title, icon, iconColor, iconBgColor, className, to, elevation, hoverable, onClick, ...rest}) => {

	const CardContent = <Paper style={{height: '100%', cursor: 'pointer'}} elevation={elevation} hoverable={hoverable}>
		{ 
			icon && <span className={`card-icon`} style={{color: iconColor, backgroundColor: iconBgColor}}>
				<FontAwesomeIcon icon={['fad', icon]} />
			</span>
		}
		{ title && <h4>{title}</h4> }
		<div className='card-inner-wrapper'>
	    	{ children }
		</div>
	</Paper>;

    return (<ThemeProvider theme={theme}>
        <StyledCard {...rest} onClick={() => onClick()} className={`card ${className}`}>
        	{ 
        		to && <Link to={to}>
		        	{CardContent}
	        	</Link>
        	}
        	{ !to && CardContent}
        </StyledCard>
    </ThemeProvider>)
};

Card.defaultProps = {
	icon: null,
	iconColor: "blue1",
	iconBgColor: "blue4",
	title: null,
	to: null,
	elevation: 1,
	hoverable: false,
	onClick: () => {}
}

export default Card;