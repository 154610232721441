import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: { 
      main: '#475cff'
    },
    secondary: { 
      main: '#11cb72',
      contrastText: '#fff'
    },
    error: { main: '#ff5451' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text:{
      primary: '#444e5a'
    }
  },
  typography: {
    fontFamily: 'Gilroy'
  }
});

export default theme;
