import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledSimpleTag = styled.span`

    --bg-color : ${props => props.bgColor};
    --text-color : ${props => props.textColor};

    &.tag-blue{
        --bg-color : ${props => props.theme.blue4};
        --text-color : ${props => props.theme.blue1};
    }
    
    &.tag-red{
        --bg-color : ${props => props.theme.red1};
        --text-color : ${props => props.theme.red};
    }

    &.tag-green{
        --bg-color : ${props => props.theme.green4};
        --text-color : ${props => props.theme.green};
    }

    &.tag-yellow{
        --bg-color : ${props => props.theme.yellowl};
        --text-color : ${props => props.theme.yellow};
    }

    position: relative;


    display:flex;
    justify-content:space-between;
    align-items:center;

    background: var(--bg-color);
    color: var(--text-color);
    border-radius: ${props => props.fullRounded ? "18px": "5px"};
    border: 1px solid var(--text-color);
    overflow: hidden;
    padding: 6px 12px;
    text-align: center;

    font-family: "Avenir Next Demi Bold";
    font-size: 16px;
    line-height: normal;
    font-weight: normal;

    .tag-remove{
        margin-left: 12px;
        margin-top: 2px;
        cursor: pointer;
    }
`;

const StyledType = styled.span`
    --bg-color : ${props => props.bgColor};

    &.type-blue{
        --bg-color : ${props => props.theme.blue1};
    }
    
    &.type-red{
        --bg-color : ${props => props.theme.red};
    }

    &.type-green{
        --bg-color : ${props => props.theme.green};
    }

    &.type-yellow{
        --bg-color : ${props => props.theme.yellow};
    }  

    display: inline-block;
    padding: 1px 6px;
    margin-left: 12px;
    color: #fff;
    background: var(--bg-color);
    font-size: 10px;
    letter-spacing: 1px;
    font-family: "Avenir Next";
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 600;
`;

const SimpleTag = ({color, textColor, bgColor, typeLabel, style, children, fullRounded, onRemove, ...rest}) => <StyledSimpleTag
    className={ color ? `tag-${color}` : ''}
    style={style}
    textColor={textColor}
    bgColor={bgColor}
    fullRounded={fullRounded}
>
    { children }
    {
        typeLabel && <StyledType className={color ? `type-${color}` : ''} bgColor={textColor} >{typeLabel}</StyledType>
    }
    {
        onRemove !== null && <div className="tag-remove" onClick={() => onRemove()}>
            <FontAwesomeIcon icon='times' />
        </div>    
    }
</StyledSimpleTag>


SimpleTag.defaultProps = {
	color: null,
    textColor: "#475cff",
    bgColor: "#eceeff",
    typeLabel: null,
    style: {},
    fullRounded: false,
    append: null,
    onRemove: null
}

export default SimpleTag;