import React from 'react';
import { useParams } from "react-router-dom";
import { observer, inject, Provider} from 'mobx-react';
import styled from 'styled-components';
import theme from '../../theme';

import DOMPurify from 'dompurify';
import { weakKey, jsonPrettyPrint } from '../../utils';

import {Paper} from '../Containers';
import Separator from '../Separator/Separator';

import Button from '@material-ui/core/Button';

import ClassifyStore from "./ClassifyStore";


const StyledTextArea = styled.div`
	flex: 2;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 24px;

	h4{
		width: 100%;
	}

	textarea{
		border: 1px solid ${props => props.theme.black3};
	    overflow: auto;
	    outline: none;
	    resize: none;
	    width: 100%;
	    font-family: "Avenir Next";
	    font-size: 12px;
	    line-height: 20px;
	    padding: 12px;
	    flex-grow: 1;
	}

	.Textarea__footer{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 12px 0;
		width: 100%;
	}
`;

const StyledTagListWrapper = styled.div`
	flex: 1;
	margin: 0px 24px 24px;
	display: flex;
	flex-direction: column;

	.TagList__header{
		color: ${props => props.theme.black2};
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid ${props => props.theme.black2};

		span{
			padding: 6px 12px;
			flex: 1;
			text-transform: uppercase;
			font-size: 12px;
			letter-spaciing: 0;
		}
	}

	.TagList__footer{
		form{
			padding: 12px 0;
			display: flex;
			flex-direction: column;
		}

		input{
			margin-bottom: 12px;
		}
	}

	.align-right{
		text-align: right;
	}
`;

const StyledTagList = styled.ul``;

const StyledTagRow = styled.li`
	display: flex;
	border-bottom: 1px solid ${props => props.theme.black3};

	& > span{
		flex: 1;
		padding: 12px 12px;
		font-weight: ${props => props.index === 0 ? "600": 'normal'};
	}

	.Tag__confidence {
		text-transform: uppercase;
		color: ${props => props.theme[props.color]};
	}
`;

const StyledTabs = styled.div`
	position: relative;
	height: 100%;

	.tabs-switcher{
	    position: absolute;
	    top: 12px;
	    right: 12px;
	    display: flex;
	    padding: 12px 0;

	    li{
	    	padding: 0 6px;
	    	margin: 0 6px;
	    	cursor: pointer;
	    	user-select: none;
	    	font-family: "Avenir Next";
	    	line-height: normal;
	    	font-weight: 600;
	    	color: ${props => props.theme.black2};
	    	text-transform: uppercase;

	    	&.current{
	    		border-bottom: 3px solid ${props => props.theme.blue2};
	    	}

	    	&.current, &:hover{
	    		color: ${props => props.theme.blue2};
	    	}
	    }
	}

	.tabs{
		height: 100%;

		.tab{
			display: none;
			padding-top: 58px;
			height: 100%;

			&.tab-json{
				background-color: ${props => props.theme.black};
				color: #fff;
				overflow: auto;
				padding: 58px 24px 24px;
				font-size: 12px;

				.json-key {
					color: ${props => props.theme.red2};
				}
				.json-value {
					color: ${props => props.theme.blue2};
				}
				.json-string {
					color: ${props => props.theme.yellow};
				}

			}

			&.current{
				display: block;
			}
		}
	}
`


const Aside = inject('ClassifyStore')(
	observer(({ClassifyStore, ...props}) => {
		return <div style={{flex: 1, overflow: "hidden"}}>
			<StyledTabs>
				<ul className="tabs-switcher">
					<li onClick={() => ClassifyStore.currentTabIndex = 0 } className={ ClassifyStore.currentTabIndex === 0 ? 'current' : '' }>list</li>
					<li onClick={() => ClassifyStore.currentTabIndex = 1 } className={ ClassifyStore.currentTabIndex === 1 ? 'current' : '' }>Json</li>
				</ul>
				<div className='tabs'>
					<div className={ `tab ${ClassifyStore.currentTabIndex === 0 ? 'current' : '' }` }>
						<TagList/>
					</div>
					<div className={ `tab tab-json ${ClassifyStore.currentTabIndex === 1 ? 'current' : '' }` } >
						<pre dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(jsonPrettyPrint(ClassifyStore.json))}} ></pre>
					</div>
				</div>
			</StyledTabs>
		</div>
	})
);

const TextArea = inject('ClassifyStore', 'ModelsStore')(
	observer(({ClassifyStore, ModelsStore, ...props}) => {
		let { modelId, modelType } = useParams();
		return <StyledTextArea>
			<h4>Test with your own text</h4>
			<textarea value={ClassifyStore.text} onChange={(e) => ClassifyStore.setText(e.currentTarget.value || "")}></textarea>
			<div className='Textarea__footer'>
				<Button 
					color="secondary"
					variant="contained"
					size="large"
					onClick={() => ClassifyStore.classify(ModelsStore.API_URL, modelId, modelType)}
					disabled={ClassifyStore.text === ""}
				>
					Classify
				</Button>
			</div>			
		</StyledTextArea>
	})
);

const TagRow = ({label, confidence, confidenceColor, index}) => {
	return <StyledTagRow color={confidenceColor} index={index}>
		<span className="Tag__name">{label}</span>
		<span className="Tag__confidence align-right">{confidence}{confidence === null ? "" : " %"}</span>
	</StyledTagRow>
};

const TagList = inject('ClassifyStore')(
	observer(({ClassifyStore, ...props}) => {
		return (<StyledTagListWrapper>
				<div className='TagList__header'>
					<span>TAG</span>
					<span className="align-right">Confidence</span>
				</div>

				<StyledTagList>
					{
						ClassifyStore.tags.map((tag, index) => {
							return <TagRow label={tag.label} confidence={Math.round(tag.confidence * 100, 2)} confidenceColor={tag.confidenceColor} key={weakKey(tag)} index={index}/>
						})
					}
				</StyledTagList>
			</StyledTagListWrapper>)
	})
);

const Classify = () => {

	let classifyStore  = new ClassifyStore();

	return <Provider ClassifyStore={classifyStore}>
		<Paper style={{display: "flex", width: "100%", minHeight: "600px", padding: "0", overflow: "hidden", maxHeight: "600px"}}>
			<div style={{flex: 2}}>
				<TextArea />
			</div>
			<Separator style={{padding: "0", "margin": "12px 0"}} lineColor={theme.black4}/>
			<Aside />
		</Paper>
	</Provider>
}
  	

export default Classify;

