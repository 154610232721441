const theme = {
	blue: '#2e43e7',
	blue1: '#475cff',
	blue2: '#6b79ff',
	blue3: '#daddff',
	blue4: '#eceeff',
	blue5: '#f6f7ff',

	red: '#ff5451',
	red2: '#fea090',
	red1: '#ffecec',

	green: '#11cb72',
	green1: '#22e186',
	green2: '#44e89c',
	green3: '#a4f4ce',
	green4: '#e7fcf2',

	yellow: '#ffd98c',
	yellowl: '#fffbf3',
	yellow2: '#fef5e2',

	orange: '#ff9800',
	violet: '#b9b4ff',
	purple: '#d6a5ff',

	black: '#444e5a',
	black1: '#8292a5',
	black2: '#bfcad8',
	black3: '#e5eaef',
	black4: '#f3f3f3',
	black5: '#f9f9fa',

	background: '#f7f7f9',

	shadow: (level, background = '32,45,113') => {
		let shadow = '';
		if(level === 0) return '';
		switch(level){
			case 1:
			default:
				shadow =  `0 1px 3px rgba(${background}, 0.08), 0 1px 2px rgba(${background}, 0.08)`; 
				break;

			case 2:
				shadow =  `0 3px 6px rgba(${background}, 0.08), 0 3px 6px rgba(${background}, 0.08)`; 
				break;

			case 3:
				shadow =  `0 10px 20px rgba(${background}, 0.08), 0 6px 6px rgba(${background}, 0.08)`; 
				break;

			case 4:
				shadow =  `0 14px 28px rgba(${background}, 0.08), 0 10px 10px rgba(${background}, 0.08)`; 
				break;

			case 5:
				shadow =  `0 19px 38px rgba(${background}, 0.08), 0 15px 12px rgba(${background}, 0.08)`; 
				break;

		}
		return `box-shadow: ${shadow}`;
	}
};
export default theme;