import React from 'react';
import clsx from 'clsx';
import { observer, inject} from 'mobx-react';
import {NavLink} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import LogoReecall from '../components/Icons/LogoReecall';
import RcBreadcrumb, {withBreadcrumbs} from '../components/Breadcrumbs/Breadcrumbs';

import routes from '../routes';

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	appBar:{
		backgroundColor: "#fff",
		borderRadius: 8,
		boxShadow: '0 1px 3px rgba(32,45,113,0.08), 0 1px 2px rgba(32,45,113,0.08)',
		zIndex: 1000
	},
	appBarFixed:{
		top: 12,
		right: 24,
		left: 24,
		width: 'auto',
	    [theme.breakpoints.up('md')]: {
			left: props => props.drawerCloseWidth + 24,
	    },
	},
	title: {
		fontWeight: 'bold',
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	aside:{
		display: 'flex',
    	alignItems: 'center'
	},
	formControlSelect: {
		marginTop: 12,
		marginBottom: 12,
		marginLeft: 24,
		minWidth: 240,
	},
	blury:{
		height: 12,
		backgroundColor: '#f7f7f9',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
	},
	logoMobile: {
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	}
}));

const Header = inject('UiStore')(
	observer(({ UiStore, ...props}) => {
  		const classes = useStyles({
			drawerCloseWidth: UiStore.drawerCloseWidth
  		});

		const Breadcrumbs = withBreadcrumbs(routes)(RcBreadcrumb);

		return <AppBar 
      			position={UiStore.headerFixed ? "fixed" : "static"}
      			color='inherit'
      			className={clsx(classes.appBar, UiStore.headerFixed ? classes.appBarFixed : {})}
			>	
				{UiStore.headerFixed && <div className={classes.blury} /> }
		        <Toolbar>
					<LogoReecall
						onlyIcon
						style={{
							marginRight: 12,
							cursor: "pointer"
						}}
						onClick={() => 
	  						UiStore.openMenu()
	  					}
	  					className={classes.logoMobile}
					/>
					<Breadcrumbs />

         			<div className={classes.grow} />
         			<div className={classes.aside}>
         				
						{
							/*
				            <IconButton aria-label="show 4 new mails">
				              	<Badge badgeContent={4} color="primary">
				                	<MailIcon />
				              	</Badge>
				            </IconButton>
				            <IconButton aria-label="show 17 new notifications" >
				              	<Badge badgeContent={17} color="primary">
				                	<NotificationsIcon />
				              	</Badge>
				            </IconButton>

							<FormControl variant="outlined" className={classes.formControlSelect} margin="dense">
								<InputLabel id="header-projectId">{"ProjectId/Client"}</InputLabel>
								<Select
									labelId="header-projectId"
									id="demo-simple-select-outlined"
									label="ProjectId/Client"
									autoWidth
									value={''}
								>
									<MenuItem value="">
									<em>None</em>
									</MenuItem>
									<MenuItem value={10}>Reecall</MenuItem>
									<MenuItem value={20}>Lorem ipsum</MenuItem>
									<MenuItem value={30}>Ma Boulangerie</MenuItem>
								</Select>
							</FormControl>
							*/	
						}

			            <NavLink to="/logout">
			            	<IconButton aria-label="logout">
			                	<ExitToAppIcon />
				            </IconButton>
				        </NavLink>
		          	</div>
		        </Toolbar>
      		</AppBar>
	})
)

export default Header;