import React, { useState } from 'react';
import { observer, inject} from 'mobx-react';
import { Redirect } from 'react-router';
import { StyledTitle } from './ModelCreator';
import { getDataSourceByType } from '../constants/dataSourcesDatas';
import FileInput from '../components/FileInput/FileInput';
import "../scss/Mixins.scss";

const ModelCreatorUploader = inject('ModelCreatorStore')(
	observer(({ModelCreatorStore, ...props}) => {
		if(!ModelCreatorStore.dataSourceFileType){
			return <Redirect to="/" />
		}

		const [ isUploaded, setIsUploaded ] = useState(false);
		const currentDataSource = getDataSourceByType(ModelCreatorStore.dataSourceFileType);

        if(isUploaded){
			return <Redirect to="/ModelCreator/Preview" />
		}

		return <>
				<StyledTitle>
					<h2>{currentDataSource.title}</h2>
					<p className="subtitle">{currentDataSource.description}</p>
				</StyledTitle>

				<FileInput
					accept={currentDataSource.accept}
					onLoad={(datas) => {
						ModelCreatorStore.setUploadedTexts(datas);
						setIsUploaded(true);
					}}
				/>
		</>
	})
);

export default ModelCreatorUploader;