import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject} from 'mobx-react';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import Checkbox from '../components/Checkbox/Checkbox';
import {Paper} from '../components/Containers';

import Button from '@material-ui/core/Button';

const StyledHeaderActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding-bottom: 4px;

	.rc-checkbox-text{
		font-size: 14px;
		font-family: "Avenir Next Demi Bold";
	}
`

const StyledTable = styled.div`
	overflow: auto;
	width: 100%;
	max-height: calc(100vh - 350px);
	border-radius: 6px;
	padding-bottom: 40px;

	table{
		width: 100%;	
	    margin-bottom: 0;
	    background-color: #fff;
	}

	.header-row{
		td{
			display: flex;
			align-items: center;
			justify-content: center;	
		}

		.rc-checkbox-text{
			font-size: 14px;
			font-weight: 600;
		}
	}

	th, td{
	    border-top: none;
	    border-right: 1px solid ${props => props.theme.black3};
	    border-bottom: 1px solid ${props => props.theme.black3};
	    color: ${props => props.theme.black};
	    font-size: 14px;
	    padding: 8px;
	    width: 100%;
	    min-width: 250px;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	}

	th{
	    display: flex;
	    flex-wrap: nowrap;
	    align-items: center;
	    justify-content: space-evenly;
		font-weight: 600;
		color: ${props => props.theme.black1};
	}

	tr{
		position: relative;
		display: flex;

		td, th{
			&:nth-child(2){
				border-left: 0;
			}
		}


		&:not(.disable){
			td.highlighted{
		    	background-color: ${props => props.theme.black5};
		    }
			
		    th,td{
		    	&.selectedText{
		    		font-weight: 600;
		    	}

		    	&.selectedTag{
		    		font-weight: 600;
		    	}
		    }
		}

		&.disable{
			background-color: ${props => props.theme.black3};
		}
	}
	
	.td-line-number{
		width: 50px;
	    min-width: 50px;
		background-color: ${props => props.theme.black4};
		border-bottom: none;
		color: ${props => props.theme.black1};
		font-family: "Avenir Next Semi";
		font-weight: 600;
		text-align: center;
	}

	.table-footer{
		position: absolute;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    padding: 8px;
		text-align: center;
	    color: ${props => props.theme.black2};
	    font-size: 14px;
    	background-color: #fff;
	}

`;

const HeaderRow = ({maxLength, onMouseEnter, onMouseLeave, hoverIndex, selectedTextIndex, selectedTagIndex, onSelectAsText, onSelectAsTag}) => {
	let formatedRow = new Array(maxLength).fill("");
	return <tr className="header-row" key={`header-row`}>
		<th className="td-line-number"></th>
		{
			formatedRow.map((col, index) => <th
				key={`header-col-${index}`}
				onMouseEnter={() => onMouseEnter(index)}
				onMouseLeave={() => onMouseLeave(index)}
				className={`${hoverIndex === index ? 'highlighted': ''} ${selectedTextIndex === index ? 'selectedText' : ''} ${selectedTagIndex === index ? 'selectedTag' : ''}`}
			>
				<Checkbox 
					checked={selectedTextIndex === index}
					onChange={(checked) => onSelectAsText(index)}
					disabled={selectedTagIndex === index}
				>
					Text column
				</Checkbox>
				<Checkbox
					checked={selectedTagIndex === index}
					onChange={(checked) => onSelectAsTag(index)}
					disabled={selectedTextIndex === index}
				>
					Tag column
				</Checkbox>
			</th>)
		}
	</tr>
}

const Row = ({row, maxLength, rowIndex, hoverIndex, selectedTextIndex, selectedTagIndex}) => {
	let formatedRow = new Array(maxLength).fill("");
	row.map((col, i) => {
		formatedRow[i] = col;
		return col;
	});
	return formatedRow.map((col, index) => <td 
		key={`col-${rowIndex}-${index}`}
		className={`${hoverIndex === index ? 'highlighted': ''} ${(selectedTextIndex === index) ? 'selectedText' : ''} ${(selectedTagIndex === index) ? 'selectedTag' : ''}`}
	>
		{col}
	</td>);
}

const ModelCreatorPreview = inject('ModelCreatorStore')(
	observer(({ModelCreatorStore, history, ...props}) => {
		if(ModelCreatorStore.uploadedTexts.length === 0){
			return <Redirect to="/" /> 
		}

		const [ discardFirstRow , setDiscardFirstRow] = useState(false);
		const [ hoverIndex, setHoverIndex] = useState(null);
		const [ selectedTextIndex, setSelectedTextIndex] = useState(null);
		const [ selectedTagIndex, setSelectedTagIndex] = useState(null);

		let maxLength = 1;
		ModelCreatorStore.uploadedTexts.slice(0, 10).map((row) => {
			maxLength = row.length > maxLength ? row.length : maxLength;
			return maxLength;
		});
		return <>
			<StyledHeaderActions>
				<Checkbox 
					checked={discardFirstRow} 
					onChange={() => setDiscardFirstRow(!discardFirstRow)}
				>Discard First Row</Checkbox>
			</StyledHeaderActions>

			<Paper style={{width: '100%', padding: '0', position: 'relative'}}>
				<StyledTable>
					<table>
						<tbody>
							{
								ModelCreatorStore.uploadedTexts.map((row, index) => {
									return <Fragment  key={`row-${index}`}>
										{ index === 0 && <HeaderRow 
											maxLength={maxLength} 
											onMouseEnter={(index) => setHoverIndex(index)}
											onMouseLeave={(index) => setHoverIndex(null)}
											hoverIndex={hoverIndex}
											selectedTextIndex={selectedTextIndex}
											selectedTagIndex={selectedTagIndex}
											onSelectAsText={(index) => selectedTextIndex === index ? setSelectedTextIndex(null) : setSelectedTextIndex(index)}
											onSelectAsTag={(index) => selectedTagIndex === index ? setSelectedTagIndex(null) : setSelectedTagIndex(index)}
										/> }
										<tr className={index === 0 && discardFirstRow ? "disable" : ""}>
											<td className="td-line-number">{index + 1}</td>
											<Row 
												row={row}
												maxLength={maxLength}
												rowIndex={index}
												hoverIndex={hoverIndex}
												selectedTextIndex={selectedTextIndex}
												selectedTagIndex={selectedTagIndex}
											/>
										</tr>
									</Fragment>
								})
							}
						</tbody>
					</table>
					<div className="table-footer">
						{`${ModelCreatorStore.uploadedTexts.length - 1} rows`}
					</div>
				</StyledTable>
			</Paper>

			<Button
				color="primary"
				variant="contained"
				size="large"
				style={{marginTop: '24px'}}
				onClick={(e) => {
					if(!selectedTextIndex) e.preventDefault();
					let tags = [];
					ModelCreatorStore.uploadedTexts.map((elt, rowIndex) => {
						if(discardFirstRow && rowIndex === 0){
							return null;
						}
						ModelCreatorStore.addRow(elt[selectedTextIndex], selectedTagIndex ? elt[selectedTagIndex] : null);
						tags.push(selectedTagIndex ? elt[selectedTagIndex] : null);
						return null;
					});

					tags.filter(elt => elt).map(elt => {
						ModelCreatorStore.addTag(elt);
						return null;
					});

      				history.push('/ModelCreator/Tags');
				}}
			>
				Confirm
			</Button>

		</>
	})
);

export default withRouter(ModelCreatorPreview);