import React, { useCallback, useState } from 'react';
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { processCSVData } from "../../utils";

const UploadWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    width: 100%;
    border-radius: 6px;
    background-color: #fff;
    padding: 12px 12px 12px 24px;
    overflow: hidden;

    color: ${props => props.hasFile ? props.theme.green : props.theme.black1};
    font-family: "Avenir Next Semi";
    font-weight: 600;

    &:hover{
        button{
            background-color: ${props => props.theme.blue2};
        }
    }

    span{
        pointer-events: none;
        display: flex;
        align-items: center;

        svg{
            margin-right: 12px;
            font-size: 20px;
        }
    }

    .rc-fileInput{
        width: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
        font-size: 100px;
    }
`;

const FileInput = ({onLoad, placeholder, btnText, accept, ...props}) => {
    const [filename, setFilename] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setFilename(file.name);
                onLoad(processCSVData(binaryStr));
            }
            reader.readAsText(file)
        })
    
    }, [onLoad])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return <UploadWrapper hasFile={!!filename} {...getRootProps()}>
            <span>
                { !filename && <FontAwesomeIcon icon='file-exclamation'/> }
                { filename && <FontAwesomeIcon icon='file-check'/> }
                { filename || placeholder }
            </span>
            <Button>{btnText}</Button>
            <input {...getInputProps({multiple: false})} />
        </UploadWrapper>
}

FileInput.defaultProps = {
    onLoad: (datas) => { console.log(datas, "uploaded datas"); },
    placeholder: "No file Selected",
    btnText: "Browse File",
    accept: ""
}

export default FileInput;