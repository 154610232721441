import React from 'react';
import { useParams } from 'react-router-dom'; 
import { observer, inject} from 'mobx-react';
import Layout from '../layout/layout';

import {Wrapper as PageWrapper} from '../components/Page';

import Tabs from '../components/Tabs/Tabs';
import Loader from '../components/Loader/Loader';

import ModelDetailTest from './ModelDetail-Test';
import ModelDetailAPI from './ModelDetail-API';
import ModelDetailStats from './ModelDetail-Stats';
import ModelDetailTeach from './ModelDetail-Teach';
// import ModelDetailRevise from './ModelDetail-Revise';
import ModelDetailExtractor from './ModelDetail-Extractor';

const ModelDetail = inject('ModelsStore')(
	observer(({ModelsStore, tab, ...props}) => {
		let { modelId } = useParams();
		let currentModel = ModelsStore.modelById(modelId);

		return <Layout>
			<PageWrapper>
		        {!ModelsStore.loading && typeof currentModel !== undefined && <>
		    		<Tabs tab={tab}>
				      	{
				      		currentModel.family !== "extractor" && <div label="Test" icon="vial">
					    		{ !ModelsStore.loading && <ModelDetailTest /> }
							</div>
						}
				      	{
				      		currentModel.family !== "extractor" && <div label="Teach" icon="book">
				      			{ !ModelsStore.loading && <ModelDetailTeach /> }
				      		</div>
				      	}
				      	{
				      		currentModel.family === "extractor" && <div label="Test" icon="vial">
				      			{ !ModelsStore.loading && <ModelDetailExtractor /> }
				      		</div>
				      	}
				      	{
				      		/*currentModel.family !== "extractor" && <div label="Revise" icon="hammer">
				      			{ !ModelsStore.loading && <ModelDetailRevise /> }
				      		</div>*/
				      	}
				      	<div label="API" icon="code">
					    	{ !ModelsStore.loading && <ModelDetailAPI /> }
				      	</div>
				      	{
				      		currentModel.family !== "extractor" && <div label="Stats" icon="chart-bar">
				      			{ !ModelsStore.loading && <ModelDetailStats /> }
				      		</div>
				      	}
				    </Tabs>
				</>}
				{ModelsStore.loading && <Loader />}
			</PageWrapper>
		</Layout>
	})
);

export default ModelDetail;