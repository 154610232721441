import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { observer, inject} from 'mobx-react';
import styled from 'styled-components';
import TagInput from '../components/TagInput/TagInput';
import SimpleTag from '../components/SimpleTag/SimpleTag';

import Button from '@material-ui/core/Button';

const StyledTagsList = styled.div`
	max-width: 600px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 12px;

	& > span{
		margin: 12px 12px 0 12px;
		border: none;
		user-select: none;
	}
`;

const ModelCreatorTags = inject('ModelCreatorStore')(
	observer(({ModelCreatorStore, ...props}) => {
		const [inputValue, setInputValue]= useState('');
		return <>
			<TagInput 
				value={inputValue}
				onChange={(e) => setInputValue(e.currentTarget.value)}
				onSubmit={(e) => {
					if(inputValue.length > 2 && ModelCreatorStore.tags.filter(tag => tag.label === inputValue).length === 0){
						ModelCreatorStore.addTag(inputValue, () => {
							setInputValue('');
						});
					}
				}}
			/>
			<StyledTagsList>
				{
					ModelCreatorStore.tags.map((tag, index) => <SimpleTag 
						fullRounded 
						key={`tag-${index}`}
						onRemove={() => {
							ModelCreatorStore.removeTag(index);
						}}
						textColor={tag.textColor}
						bgColor={tag.bgColor}
					>
						{tag.label}
					</SimpleTag>)
				}
			</StyledTagsList>

			<Button color="primary" variant="contained" size="large" style={{marginTop: '24px'}}>
				<Link to={"/ModelCreator/Custom"} >
					Continue
				</Link>
			</Button>
		</>
	})
);

export default ModelCreatorTags;