import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Lock from './Lock';
import Utils from './Utils';

class Logout extends Component {
	componentDidMount(){
		const { UserStore } = this.props;
		UserStore.loading = true;
		Utils.clearLoginLocaleStorage();
		Lock.logout();
	}

	render() {
		return (
			<Redirect to={{ pathname: '/login', state: { from: this.props.location } }}/>
		);
	}
}

export default inject('UserStore')(observer(Logout));
