var mapKeys = new WeakMap();
var indexKeys = 0;
export function weakKey(obj) {
  var key = mapKeys.get(obj);
  if (!key) {
    key = 'weak-key-' + indexKeys++;
    mapKeys.set(obj, key);  
  }
  return key;
}

export function countWords(s){
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function(str){return str !== "";}).length;
    //return s.split(' ').filter(String).length; - this can also be used
}

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}

export function chunk_array(array, size) {
  const chunked_arr = [];
  let copied = [...array]; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
  for (let i = 0; i < numOfChild; i++) {
    chunked_arr.push(copied.splice(0, size));
  }
  return chunked_arr;
}

function jsonReplacer(match, pIndent, pKey, pVal, pEnd) {
    let key = '<span class=json-key>';
    let val = '<span class=json-value>';
    let str = '<span class=json-string>';
    let r = pIndent || '';
    if (pKey)
       r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
    if (pVal)
       r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>';
    return r + (pEnd || '');
};

export function jsonPrettyPrint(obj){
  let jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;

  return JSON.stringify(obj, null, 3)
     .replace(/&/g, '&amp;')
     .replace(/\\"/g, '&quot;')
     .replace(/</g, '&lt;')
     .replace(/>/g, '&gt;')
     .replace(jsonLine, jsonReplacer);
}

export const shuffle_array = arr => arr
  .map(a => [Math.random(), a])
  .sort((a, b) => a[0] - b[0])
  .map(a => a[1]);

export const processCSVData = (csv) => {
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    for (var i=0; i<allTextLines.length; i++) {
        var data = allTextLines[i].split(';');
            var tarr = [];
            for (var j=0; j<data.length; j++) {
                tarr.push(data[j]);
            }
            lines.push(tarr);
    }
    return lines;
}

export function lookup(obj, path, cb, safeCallBack = true) {
    if (!path) return obj;
    if (!(path instanceof Array)) path = path.split('.');
    let current = obj;
    for (let k of path) {
      if (!(current instanceof Object)) break;
      current = current[k];
    }
    return !((current === null || current === undefined) && safeCallBack) && cb ? cb(current) : current;
}

export function getHighestRole(list, fieldName){
    const roles = ["owner", "admin", "manager", "employee", "user"];
    list.sort((a, b) => { return roles.indexOf(a[fieldName]) - roles.indexOf(b[fieldName]) });
    return list.length >= 0 ? list[0][fieldName] : null;
}