import theme from "../theme";
const modelFamilyDatas = [
	{
		title: "classifier",
		icon: "tags",
		iconColor: theme.green,
		iconBgColor: theme.green4,
		color: theme.green,
		description: "Classify texts into the tags that you define."
	},
	{
		title: "detector",
		icon: "cctv",
		iconColor: theme.red,
		iconBgColor: theme.red1,
		color: theme.red,
		description: "Detect intents from yout text"
	},
	{
		title: "extractor",
		icon: "hashtag",
		iconColor: theme.blue1,
		iconBgColor: theme.blue4,
		color: theme.blue1,
		description: "Extract specific pieces of data from your text."
	}
]

export default modelFamilyDatas;