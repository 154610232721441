import React from 'react';
import styled from 'styled-components';


const StyledTagList = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-right: -12px;
	margin-left: -12px;
	margin-top: 6px; 
	margin-bottom: 6px; 
`;
const StyledTag = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 24px;
	color: ${props => props.selected ? "#fff" : props.theme.black1 };
	border: 1px solid ${props => props.theme.black3};
	border-radius: 18px;
	background-color: ${props => props.selected ? props.theme.blue1 : "#fff"};
	margin: 6px;
	font-size: 14px;
	line-height: 18px;
	flex-basis: 200px;
	cursor: pointer;
	user-select: none;

	&:hover{
		color: ${props => props.selected ? "#fff": props.theme.blue1};
		border-color: ${props =>  props.selected ? props.theme.black3 : props.theme.blue1};
	}
`;

export const Tag = ({label = "", selected = false, onClick = () => {}}) => {
	return <StyledTag selected={selected} onClick={onClick}>
			{label}
		</StyledTag>
};

const TagList = ({tags = [], style, onClick = () => {}, multiple = false, ...props}) => {
	return <StyledTagList style={style}>
			{
				tags.map((tag, index) => {
					return <Tag 
						label={tag.label}
						selected={tag.selected}
						key={`tag-${index}`}
						onClick={() => onClick(tag)}
					/>
				})
			}
		</StyledTagList>
}

export default TagList;