import UiStore from './uiStore';
import UserStore from './userStore';
import ModelsStore from './modelsStore';
import ModelCreatorStore from './modelCreatorStore';
import SpiderStore from './spiderStore';

export default class GlobalStore {
    constructor() {
		this.UserStore = new UserStore(this);
		this.ModelsStore = new ModelsStore(this);
		this.ModelCreatorStore = new ModelCreatorStore(this);
		this.SpiderStore = new SpiderStore(this);
		this.UiStore = new UiStore(this);
    }
}

