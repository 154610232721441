import {decorate, observable, action} from "mobx";
import {modelCreatorStepDatas, modelCreatorStepDatasUploader, modelCreatorStepDatasCustom } from '../constants/modelCreatorStepDatas';
const shortid = require('shortid');

class DatasetRow {
  constructor({text = "", tag = null}) {
    this.id = shortid.generate();
    this.text = text;
    this.tag = tag;
  }
}
decorate(
  DatasetRow, {
    id: observable,
    text: observable,
    tag: observable
})

class ModelCreatorStore {
  creationType = null;
  type = null;
  dataSourceFileType = null;
  datasetName = "";
  datasetRows = [];
  tags = [];
  colorIndex = 0;
  uploadedTexts = [];

  setCreationType = (creationType) => {
    this.creationType = creationType
  }

  setDataSourceFileType = (dataSourceFileType) => {
    this.dataSourceFileType = dataSourceFileType
  }

  setType = (type) => {
    this.type = type;
  }

  setUploadedTexts = (texts) => {
    this.uploadedTexts = texts;
  }

  addRow(text = "", tag = null){
    this.datasetRows = [...this.datasetRows, new DatasetRow({text, tag})];
  }

  setRows = (rows = []) => {
    this.datasetRows = rows;
  }

  getRowIndexById(id){
    return this.datasetRows.findIndex(row => row.id === id);
  }

  setTextByRowId = (id, text) => {
     this.datasetRows[this.getRowIndexById(id)].text = text;
  }

  removeTextByRowId = (id) => {
    this.datasetRows.splice(this.getRowIndexById(id), 1);
  }

  setTagByRowId = (id, tagLabel) => {
     this.datasetRows[this.getRowIndexById(id)].tag = tagLabel;
  }

  setTags = (tags) => {
    this.tags = tags;
  }

  addTag = (value, cb = () => {}) => {
    this.setTags([...this.tags, {id: shortid.generate(), label: value, textColor: `#fff`, bgColor: `#475cff`}]);
    cb();  
  }

  removeTag(index){
    let newTags = [...this.tags];
    newTags.splice(index, 1);
    this.setTags([...newTags])
  }

  editTag = (index, value) => {
    let newTags = [...this.tags];
    newTags[index].label = value;
    this.setTags([...newTags])
  }

  getCurrentStep = (section) => {
    if(section === "Type") return 0;
    if(section === "Classifier" || section === "Extractor" || section === "Detection") return 1;
    if(this.creationType === "custom"){
      return ({
        "Tags": 2,
        "Custom": 3,
        "Name": 4
      })[section];
    } else {
      return ({
        "Uploader": 2,
        "Preview": 3,
        "Tags": 4,
        "Custom": 5,
        "Name": 6
      })[section];
    }
  }

  getStepDatas = (step) => {
    let stepData = modelCreatorStepDatas.find(data => data.step.includes(step)) || null;
    return stepData || this.getStepsDatas().find(data => data.step.includes(step)) || null;
  }

  getStepsDatas = () => {
    if(this.creationType === "custom"){
      return [...modelCreatorStepDatasCustom];
    } else {
      return [...modelCreatorStepDatasUploader];
    }
  }
  
}
decorate(ModelCreatorStore, {
  datasetName: observable,
  datasetRows: observable,
  creationType: observable,
  type: observable,
  uploadedTexts: observable,
  tags: observable,
  colorIndex: observable,
  dataSourceFileType: observable,
  setType: action,
  setCreationType: action,
  setDataSourceFileType: action,
  setUploadedTexts: action,
  addRow: action,
  setRows: action,
  setTagByRowId: action,
  setTextByRowId: action,
  removeTextByRowId: action,
  setTags: action,
  addTags: action,
  getCurrentStep: action,
  getStepDatas: action,
  getStepsDatas: action,
});

export default ModelCreatorStore;