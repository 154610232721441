import React from 'react';
import { observer, inject} from 'mobx-react';
import styled from 'styled-components';
import {Card} from '../components/Containers'
import DataSourcesDatas from '../constants/dataSourcesDatas';
import { StyledTitle } from './ModelCreator';
import "../scss/Mixins.scss";
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

const DataSourceCard = styled(Card)`
	text-align: center;
	margin: 12px;
	flex-basis: 160px;

	.card-icon{
		margin-left: auto;
		margin-right: auto;
	}

	& > a > div{
		padding: 26px;
	}	

	h4{
		margin-bottom: auto;
		color: ${props => props.theme.black1};
	}
`;

const DataSourceWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px;
	width: 100%;

	max-width: 1000px;
	flex-wrap: wrap;
`;

const ModelCreatorDataSource = inject('ModelCreatorStore')(
	observer(({ModelCreatorStore, ...props}) => {
		return <>
			<DataSourceWrapper>
				{
					DataSourcesDatas.map((dataSource, index) => {
						return <DataSourceCard
	        			    to={`/ModelCreator/Uploader`}
	        				title={dataSource.name}
	        				className={`col col${1 + index%4}`}
	        				icon={dataSource.icon}
	        				iconColor={dataSource.iconColor}
	        				key={`dataSource-${index}`}
	        				hoverable={true}
	        				onClick={() => {
	        					ModelCreatorStore.setDataSourceFileType(dataSource.type);
	        					ModelCreatorStore.creationType = "import";
        					}}
	    				>
						</DataSourceCard>
					})
				}
			</DataSourceWrapper>

			<StyledTitle style={{marginBottom: 24, marginTop: 24}}>
				<p className="subtitle">{"Or"}</p>
			</StyledTitle>
			
			<Button color="primary" variant="contained" size="large" onClick={() => ModelCreatorStore.creationType = "custom"}>
        		<Link to={`/ModelCreator/Tags`}>CREATE DATASET MANUALLY</Link>
        	</Button>
		</>
	})
);

export default ModelCreatorDataSource;