export const findAll = ({ caseSensitive, findChunks = defaultFindChunks, searchWords, textToHighlight }) => {
	return (
		fillInChunks({
			chunksToHighlight: combineChunks({
				chunks: findChunks({
					caseSensitive,
					searchWords,
					textToHighlight
				})
			}),
			totalLength: textToHighlight ? textToHighlight.length : 0
		})
	)
}

const defaultFindChunks = ({searchWords, textToHighlight, caseSensitive}) => {
	return searchWords
    .filter(searchWord => searchWord) // Remove empty words
	.reduce((chunks, searchWord) => {
		let searchWordText = searchWord;
		let searchWordOptions = null;

		if(typeof searchWord === "object"){
			searchWordText = searchWord.word;
			searchWordOptions = searchWord.options || null;
		}
		const regex = new RegExp(searchWordText, caseSensitive ? 'g' : 'gi')

		let match;
		while ((match = regex.exec(textToHighlight))) {
			let start = match.index
			let end = regex.lastIndex
        	// We do not return zero-length matches
			if (end > start) {
				chunks.push({highlight: false, start, end, options: searchWordOptions})
			}

	        // Prevent browsers like Firefox from getting stuck in an infinite loop
	        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
			if (match.index === regex.lastIndex) {
				regex.lastIndex++
			}
		}

		return chunks
	}, []) 
}

export {defaultFindChunks as findChunks}

export const combineChunks = ({ chunks}) => {
	chunks = chunks
	.sort((first, second) => first.start - second.start)
	.reduce((processedChunks, nextChunk) => {
      	// First chunk just goes straight in the array...
		if (processedChunks.length === 0) {
			return [nextChunk]
		} else {
        	// ... subsequent chunks get checked to see if they overlap...
			const prevChunk = processedChunks.pop()
			if (nextChunk.start <= prevChunk.end) {
	          	// It may be the case that prevChunk completely surrounds nextChunk, so take the
	          	// largest of the end indeces.
				const endIndex = Math.max(prevChunk.end, nextChunk.end)
				processedChunks.push({highlight: false, start: prevChunk.start, end: endIndex, options: null})
			} else {
				processedChunks.push(prevChunk, nextChunk)
			}
			return processedChunks
		}
	}, [])

	return chunks
}

export const fillInChunks = ({ chunksToHighlight, totalLength }) => {
	const allChunks = []
	const append = (start, end, highlight, options) => {
		if (end - start > 0) {
			allChunks.push({
				start,
				end,
				highlight,
				options
			})
		}
	}

	if (chunksToHighlight.length === 0) {
		append(0, totalLength, false, null)
	} else {
		let lastIndex = 0
		chunksToHighlight.forEach((chunk) => {
			append(lastIndex, chunk.start, false, null)
			append(chunk.start, chunk.end, true, chunk.options)
			lastIndex = chunk.end
		})
		append(lastIndex, totalLength, false, null)
	}
	return allChunks
}