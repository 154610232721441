import React, { useEffect } from 'react';
import { observer, inject} from 'mobx-react';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {SitesSort} from '../stores/spiderStore'; 

import Layout from '../layout/layout';
import {Header as PageHeader, Wrapper as PageWrapper} from '../components/Page';
import Loader from '../components/Loader/Loader';

import SimpleList from '../components/Lists/SimpleList';
import ListFilters from '../components/Spider/ListFilters';

import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const StyledLoader = styled(Loader)`
	background-color: transparent;
`;

const StyledWebsitesList = styled(SimpleList)`
	width: 100%;
	max-width: 800px;

	.MuiListItemText-secondary{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.SpiderSiteDate{
		display: flex;
		align-items: center;
		color: ${props => props.theme.black2};
		svg{
			margin-right: 6px;
		}
	}

	.emptyList{
		margin-top: 48px;
		padding: 24px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

const SpiderList = inject('SpiderStore')(
	observer(({SpiderStore, ...props}) => {

	    useEffect(() => {
	    	SpiderStore.sitesLoaded = false;
	        SpiderStore.getAllSites();
	    }, [SpiderStore]);

		const handleChangeOrder = event => {
    		SpiderStore.orderFilter = event.target.value;
  		};

		return <Layout>
			<PageWrapper>
				{
					SpiderStore.sitesLoaded && <>
						<PageHeader 
							title="Websites List"
							surtitle="Spider"
						/>
						<ListFilters>
							<div className="filtersLeft">
			              		<Button
									color="primary"
									variant="contained"
									size="small"
		              			>
									<Link to={"New"} >
										Scan a new site
									</Link>
			              		</Button>
							</div>

							<div className="filtersRight">
								<FormControl margin="dense" className="orderSelect" variant="outlined">
									<InputLabel id="sites-order-label">{"Order"}</InputLabel>
									<Select
										labelId="sites-order-label"
										id="sites-order"
										label="Order"
										autoWidth
										value={SpiderStore.orderFilter}
										onChange={handleChangeOrder}
									>
										{
											Object.entries(SitesSort).map(([value, label]) => 
												<MenuItem key={`sitesort-${value}`} value={value}>{label}</MenuItem>
											)	
										}
									</Select>
								</FormControl>
							</div>
						</ListFilters>

						<StyledWebsitesList>
							{
								SpiderStore.filteredSites.map(website => {
									return <ListItem
										key={`spider-site-${website.id}`}
						              	component={Link}
						              	button 
						              	to={website.status !== "done" ? `${website.id}/Status` : `${website.id}/Detail` }
									>
										<ListItemText 
											primary={website.title || "N/A"} 
											secondary={<>
												{website.originalUrl}
												{
													/*<span className="SpiderSiteDate"><FontAwesomeIcon icon={website.crawlingStateIcon} />{website.crawlingStateLabel}</span>*/
												}
												{
													<span className="SpiderSiteDate"><FontAwesomeIcon icon={['fa', 'sync-alt']} />Last update : {moment(website.date).format("LL - LT")}</span>
												}
											</>}
										/>
									</ListItem>
								})
							}
							{
								SpiderStore.filteredSites.length === 0 && <li className="emptyList">
									<img src="//assets.reecall.co/illustration/svg/basket-empty.svg" alt="empty list" />
								</li>	
							}
						</StyledWebsitesList>
					</>	
				}

				{
					!SpiderStore.sitesLoaded && <StyledLoader />	
				}
			</PageWrapper>
		</Layout>
	})
);

export default SpiderList;