import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const StyledButton = styled.button`
    --bg-color : ${props => props.backgroundColor};
    --text-color : ${props => props.textColor};

    position: relative;
    background: var(--bg-color);
    color: var(--text-color);
    border-radius: 5px;
    overflow: hidden;
    padding: 12px 24px;
    text-align: center;
    border: none;

    box-shadow:  0 3px 10px 0 rgba(68, 78, 90, .12);

    &:hover{
        /*text-decoration: underline;*/
    }

    &:active{
        box-shadow:  0 0px 0px 0 rgba(68, 78, 90, .12);
    }

    user-select: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Avenir Next";
    font-weight: 600;

    &:disabled{
    	color: ${props => props.theme.black2};
    	background: ${props => props.theme.black5};
    }
`;

const Button = (props) => <StyledButton {...props} > { props.children } </StyledButton>;

Button.defaultProps = {
	backgroundColor: theme.blue1,
	textColor: '#fff',
}

export default Button;