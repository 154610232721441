import React, { useEffect } from 'react';
import { observer, inject} from 'mobx-react';
import { useParams } from "react-router-dom";
import Layout from '../layout/layout';

import {Wrapper as PageWrapper} from '../components/Page';
import TagList from '../components/TagList/TagList';
// import FamilyList from '../components/FamilyList/FamilyList';
import {Card} from '../components/Containers';
import Separator from '../components/Separator/Separator';
import TextInput from '../components/TextInput/TextInput';
import Loader from '../components/Loader/Loader';
import { chunk_array } from '../utils';

const ModelExplorer = inject('ModelsStore')(
	observer(({ModelsStore, ...props}) => {
		let modelsChunks = chunk_array(ModelsStore.filteredModels, 4);
		let { modelType } = useParams();

		useEffect(() => {
			!ModelsStore.loading && modelType && ModelsStore.goToFamilyByLabel(modelType);
			!ModelsStore.loading && !modelType && ModelsStore.unselectAllFamilies();
		}, [modelType, ModelsStore.loading, ModelsStore]);

		return <Layout>
			<PageWrapper>
		        {
		        	!ModelsStore.loading && <>
			    		<TextInput 
			    			value={ModelsStore.search}
			    			onChange={(e) => ModelsStore.search = e.currentTarget.value}
			    			type="search" 
			    			style={{maxWidth: "800px", marginBottom: "24px"}}
			    			placeholder={"Search"}
			    		/>
						{
							/*
								<FamilyList 
								style={{width: '100%'}}
								families={ModelsStore.families}
								onClick={(obj) => {
									ModelsStore.toggleFamilyByLabel(obj.label);
								}}
								multiple={true}
							/>
							*/
						}	
						<TagList 
							style={{width: '100%'}}
							tags={ModelsStore.tags}
							onClick={(obj) => {
								ModelsStore.tags[ModelsStore.tags.findIndex(tag => tag.label === obj.label)].selected = !ModelsStore.tags[ModelsStore.tags.findIndex(tag => tag.label === obj.label)].selected;
							}}
							multiple={true}
						/>
			        	<Separator type="horizontal" />
			        	{
			        		modelsChunks.map((models, rowIndex) => {
			        			return <div className='CardsRow' style={{marginTop: '12px'}} key={`model-row-${rowIndex}`}>
			        			{
				        			models.map((model, index) => {
				        				return <Card
					        			    to={`/${model.family}/${model.id}/`}
					        				title={model.name}
					        				className={`card${1 + index%4}`}
					        				icon={model.icon}
					        				iconColor={model.iconColor}
					        				iconBgColor={model.iconBgColor}
					        				key={`model-${model.id}`}
					        				hoverable={true}
				        				>
				        				{
				        					model.short_description
			        					}
				    					</Card>
			    					})
		        				}
			        			</div>
			        		})
		        		}
					</>
				}
				{
					ModelsStore.loading && <Loader />
				}
			</PageWrapper>
		</Layout>
	})
);

export default ModelExplorer;