import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { observer, inject} from 'mobx-react';
import styled from 'styled-components';
import { TagCloud } from 'react-tagcloud'
import {Paper} from '../components/Containers';
import Gauge from '../components/Gauge/Gauge';
import Separator from '../components/Separator/Separator';
import TagList from '../components/TagList/TagList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const StyledWrapper = styled.div`
	display: flex;

	h3{
		margin-top: 24px;

		&:first-child{
			margin-top: 0;
		}
	}
`;

const StyledAside = styled.div`
	flex: 1;
	padding: 0 24px 0 12px;

	.mainTag{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 12px 0 0;
		color: ${props => props.theme.black1};
		font-weight: 600;
		line-height: normal;
		cursor: pointer;

		&.active, &:hover{
			color: ${props => props.theme.blue1};
		}
	}
`;

const StyledMain = styled.div`
	flex: 3;
	padding: 0 12px;

	& > div{
		margin: 12px 0;
	}

	.stats_box{
		display: flex;
		margin-bottom : 12px;

		& > div{
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}


		.stats_box--number{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-family: "Avenir Next Bold";
			font-size: 40px;
		}

		.stats_box--label{
			font-weight: 600;
			margin-bottom: 12px;
			font-size: 18px;
			color: ${ props => props.theme.black1 };
			text-transform: uppercase;
		}
	}

	.tagsCloud{
		flex: 1;
		width: 100%;
		color: ${ props => props.theme.blue1 };

		.tag-cloud-tag{
			line-height: normal;
		}
	}
`;

const ModelDetailStats = inject('ModelsStore')(
	observer(({ModelsStore, ...props}) => {
		const [loading, setLoading] = useState(true);
		const [currentCat, setCurrentCat] = useState(null);
		let { modelId } = useParams();
		let currentModel = ModelsStore.modelById(modelId);

		useEffect(() => {
		    async function getStats() {
		      await ModelsStore.fetchModelStats(modelId);
		      setLoading(false);
		    }
		    getStats();
	  	}, [ModelsStore, modelId]);

		let totalWords, cloudTags;
		if(!loading){
			totalWords = currentCat ? currentModel.stats.docCount[currentCat] : currentModel.stats.totalDocuments;
			cloudTags = currentCat
				? Object.entries(currentModel.stats.wordFrequencyCount[currentCat]).map(([key, value]) => { return {value: key, count: value}})
				: Object.entries(currentModel.stats.vocabulary).map(([key, value]) => { return {value: key, count: value}}); 
		}

		return !ModelsStore.loading && !loading && <StyledWrapper>	
				<StyledAside>
					<h3>Tags</h3>
					
					<span 
						className={`mainTag ${currentCat === null ? 'active' : ''}`}
						onClick={() => setCurrentCat(null)}
					>{currentModel.name}<FontAwesomeIcon icon='chevron-right'/></span>

					<Separator type="horizontal"/>
					<TagList 
						style={{width: '100%'}}
						tags={
							Object.entries(currentModel.stats.categories)
							.filter(([key, value]) => value)
							.map(([key, value]) => { return {label: key, selected: key === currentCat }})
						}
						onClick={(obj) => {
							console.log(obj, "obj");
							setCurrentCat(currentCat ===  obj.label ? null : obj.label);
						}}
					/>
				</StyledAside>
				<StyledMain>
					<h3>Statistics</h3>
					<Paper className={"stats_box"}>
						<div>
							<span className="stats_box--number" style={{height: "260px", padding: "30px 0"}}>
								{totalWords}
							</span>
							<span className="stats_box--label">{`Texts`}</span>
						</div>
						<div>
							<Gauge percent={80} style={{height: "260px", padding: "30px 0"}}/>
							<span className="stats_box--label">{`Accuracy`}</span>
						</div>
						<div>
							<Gauge percent={20} style={{height: "260px", padding: "30px 0"}}/>
							<span className="stats_box--label">{`F1 score`}</span>
						</div>
					</Paper>
					<h3>Keywords</h3>
					<Paper className="tagsCloud">
						<TagCloud
					        minSize={12}
    						maxSize={50}
    						colorOptions={{
							  hue: '#475cff'
    						}}
    						//disableRandomColor={true}
    						tags={cloudTags}
				        />
					</Paper>
				</StyledMain>
			</StyledWrapper>
	})
);

export default ModelDetailStats;