import React, { createElement } from 'react';
import styled from 'styled-components';
import { findAll } from './utils';

const StyledHighlightTextWrapper = styled.div`
    font-weight: normal;

    --bg-color : ${props => props.bgColor};
    --text-color : ${props => props.textColor};

    .highlighted-word{
        font-weight: 600;
        color: inherit;
        background-color: inherit;
    }
`;


const StyledHighlightText = styled.span`
    display: inline-block;
    color: ${props => props.textColor ? props.textColor : `var(--text-color)`};
    background-color: ${props => props.bgColor ? props.bgColor : `var(--bg-color)`};
`;


const HighlightText = ({textColor, bgColor, searchWords, highlightTag, caseSensitive, textToHighlight, highlightClass, className, ...rest}) => {
    const chunks = findAll({
        caseSensitive,
        searchWords,
        textToHighlight
    });
    const HighlightTag = highlightTag;
    let highlightIndex = -1;
    let isOriginalTag = true;

    return <StyledHighlightTextWrapper className={className} textColor={textColor} bgColor={bgColor} {...rest}>
    {
        chunks.map((chunk, index) => {
            const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);
            if (chunk.highlight) {
                highlightIndex++;

                const props = {
                  children: text,
                  className: `highlighted-word ${highlightClass}`,
                  options: chunk.options || {}
                }

                if (typeof HighlightTag !== 'string') {
                  props.highlightIndex = highlightIndex;
                  isOriginalTag = false;
                }

                return isOriginalTag ? <StyledHighlightText {...chunk.options} key={index}>
                    {createElement(highlightTag, props)}
                </StyledHighlightText> : createElement(highlightTag, {...props, key: index});

            } else {
                return createElement('span', {
                  children: text,
                  key: index
                });
            }
        })
    }
    </StyledHighlightTextWrapper>
}

HighlightText.defaultProps = {
    textColor: '#11cb72',
    bgColor: '#e7fcf2',
    searchWords: [],
    highlightTag: 'mark',
    caseSensitive: false,
    textToHighlight: '',
    highlightClass: '',
    autoEscape: false
}

export default HighlightText;