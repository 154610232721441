import Auth0Lock from 'auth0-lock';
import { AUTH_CONFIG } from './auth0-variables';

var queryParams = new URLSearchParams(window.location.search);

export default {
	lock: new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
		configurationBaseUrl: 'https://cdn.eu.auth0.com',
		auth: {
			redirect: false,
			redirectUrl: window.location.origin + '/login?goto=' + escape(window.location.pathname),
			responseType: 'token id_token',
			autoParseHash: true,
			params: {
				scope: 'openid profile email'
			}
		},
		avatar: null,
		closable: false,
		container: AUTH_CONFIG.container,
		language: 'fr',
		allowSignUp: false,
		prefill: queryParams.has('email') ? { email: queryParams.get('email') } : {},
		languageDictionary: {
			title: '',
			signUpTitle: '',
			captchaCodeInputPlaceholder: '',
			captchaMathInputPlaceholder: '',
			error: {
				login:{
					invalid_captcha: ''
				},
				signUp: {
					captcha_required: '',
					social_signup_needs_terms_acception: ''
				}
			}
		},
		rememberLastLogin: true,
		theme: {
			primaryColor: '#22e186',
			logo: '//assets.reecall.co/logos/svg/logo_reecall_2020.svg'
		},
		usernameStyle: 'email'
	}),

	logout: function() {
		this.lock.logout({
			clientId: AUTH_CONFIG.clientId,
			returnTo: window.location.origin + '/login'
		})
	}
};
