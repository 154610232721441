import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledCheckbox = styled.div`
    outline: none;
    display: flex;
    align-items: center;
    line-height: normal;

    border: ${props => props.checked && props.withLabel && props.bordered ? `1px solid ${props.theme.blue1}` : "" };
    border-radius: 6px;
    background-color: ${props => props.checked && props.withLabel && props.bordered ? props.theme.blue5 : 'transparent'};
    padding: ${props => props.checked && props.withLabel && props.bordered  ? '3px' : '4px'};

    .rc-checkbox-text {
        font-family: 'Avenir Next';
        font-size: 16px;
        color: ${props => props.checked && props.withLabel ? props.theme.blue1 : props.theme.black };
        font-weight: ${props => props.checked && props.withLabel ? '600' : 'normal' };
        margin-left: 12px;
        user-select: none;
    }

    .rc-checkbox-icon {
        display: flex;
        width: 22px;
        height: 22px;
        border: 1px solid ${props => props.theme.black3};
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        background-color: white;
        font-size: 16px;
        
        &.rc-radio {
            border-radius: 11px;
        }
        div {
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }
    }

    &:not(.rc-disabled) {
        cursor: pointer;
        .rc-checkbox-icon.rc-checked,
        .rc-checkbox-icon.rc-indeterminate {
            border: 1px solid ${props => props.theme.blue1};
            div {
                background-color: ${props => props.theme.blue1};
            }
            i, svg {
                color: ${props => props.theme.blue1};
            }
        }
        &:focus > .rc-checkbox-icon,
        &:hover > .rc-checkbox-icon {
            border: ${props => props.checked && props.withLabel ? "1px": "2px"} solid ${props => props.theme.blue1};
        }
    }
    &.rc-disabled > .rc-checkbox-text {
        color: ${props => props.theme.black2};
    }
    &.rc-disabled > .rc-checkbox-icon {
        div {
            background-color: ${props => props.theme.black2};
        }
        i, svg {
            color: ${props => props.theme.black2};
        }
        border: 1px solid ${props => props.theme.black3};
        background-color: #fafafb;
    }
`;

const Checkbox = ({checked, onChange, disabled, indeterminate, className, children, radio, bordered, ...props}) => <StyledCheckbox
    {...props} 
    checked={checked}
    disabled={disabled}
    indeterminate={indeterminate}
    className={classNames(className, disabled && 'rc-disabled')}
    withLabel={!!children}
    bordered={bordered}
    onClick={() => { !disabled && onChange && onChange(!checked) }}
    onKeyDown={({ code }) => { !disabled && onChange && ['Space', 'Enter'].includes(code) && onChange(!checked)}}
>
    <div className={classNames('rc-checkbox-icon', checked && !indeterminate && 'rc-checked', indeterminate && 'rc-indeterminate', radio && 'rc-radio')}>
        {checked && !indeterminate && (!radio ? <FontAwesomeIcon icon='check' /> : <div />)}
        {indeterminate &&  (!radio ? <FontAwesomeIcon icon='minus' /> : <div />)}
    </div>
    { children && <div className={classNames(disabled && 'rc-disabled', 'rc-checkbox-text')}>
        {children}
    </div>}
</StyledCheckbox>


Checkbox.defaultProps = {
	checked: false,
	onChange: () => {},
    disabled: false,
    indeterminate: false,
    radio: false,
    bordered: false
}

export default Checkbox;