import theme from "../theme";
const tagFamilyDatas = [
	{
		label: "capitales",
		bgColor: theme.green4,
		textColor: theme.green
	},
	{
		label: "couleurs",
		bgColor: theme.red1,
		textColor: theme.red
	},
	{
		label: "animaux",
		bgColor: theme.blue4,
		textColor: theme.blue1
	},
	{
		label: "departements",
		bgColor: theme.yellowl,
		textColor: theme.yellow
	},
	{
		label: "tvchannel",
		bgColor: theme.green4,
		textColor: theme.green
	},
	{
		label: "metiers",
		bgColor: theme.red1,
		textColor: theme.red
	},
	{
		label: "transports",
		bgColor: theme.blue4,
		textColor: theme.blue1
	}
]


export default tagFamilyDatas;