import React from 'react';
import { useParams } from 'react-router-dom';
import { observer, inject, Provider} from 'mobx-react';
import styled from 'styled-components';
import theme from '../../theme';

import {Paper} from '../Containers';
import Separator from '../Separator/Separator';
import Button from '../Button/Button';
import SimpleTag from '../SimpleTag/SimpleTag';
import HighlightText from '../HighlightText/HighlightText';

import {extractorStore} from "./ExtractorStore";

const StyledAside = styled.div`
	 flex: 1;
	 overflow: hidden;
	 padding: 24px;
`;

const StyledTagListWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;

	.TagList__header{
		color: ${props => props.theme.black2};
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid ${props => props.theme.black2};

		span{
			padding: 6px 12px;
			flex: 1;
			text-transform: uppercase;
			font-size: 12px;
			letter-spaciing: 0;
		}
	}

	.align-right{
		text-align: right;
	}
`;

const StyledTagList = styled.ul``;

const StyledTagRow = styled.li`
	display: flex;
	border-bottom: 1px solid ${props => props.theme.black3};

	& > span{
		flex: 1;
		padding: 12px 12px;
	}

	.Tag__type {
		color: ${props => props.theme.blue1};
		font-weight: 600;
		text-transform: capitalize;
	}

	.Tag__value {
		font-family: "Avenir Next Semi";
		text-align: right;
		font-weight: 600;
	}
`;

const StyledTextArea = styled.div`
	/*flex: 4;*/
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    flex-grow: 1;

	h4{
		width: 100%;
	}

	textarea{
		/*border: none;*/
		border: 1px solid ${props => props.theme.black3};
	    overflow: auto;
	    outline: none;
	    resize: none;
	    width: 100%;
	    font-family: "Avenir Next";

	    font-size: 16px;
	    line-height: 30px;
	   /* font-size: 12px;
	    line-height: 20px;*/
	    padding: 12px;
	    flex-grow: 1;
	}
`;

const StyledPreview = styled.div`
	flex: 4;
	padding: 12px;
	line-height: 36px;
	/*border: 1px solid #e5eaef;*/
	border-top: none;
`;

const StyledFooter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 12px 0;
	width: 100%;
	/*flex: 1;*/
	margin-bottom: 0;
	margin-top: 0;
`;


const TagRow = ({type, value, colors}) => {
	return <StyledTagRow textColor={colors.textColor} color={colors.color}>
		<span className="Tag__type">{type}</span>
		<span className="Tag__value">{value}</span>
	</StyledTagRow>
};

const Aside = inject('ExtractorStore')(
	observer(({ExtractorStore, ...props}) => {
		return <StyledAside>
				<h4>AUTOMATICALLY LABELED AS:</h4>
				<StyledTagListWrapper>
					<div className='TagList__header'>
						<span>Tag</span>
						<span className="align-right">Value</span>
					</div>
					<StyledTagList>
						{
							ExtractorStore.tags.map((tag, index) => {
								return <TagRow 
									type={tag.family.label}
									value={tag.text}
									key={`tag-${index}`}
									colors={tag.family.colors} 
								/>
								/*return <StyledTag key={`tag-${index}`}>
									<SimpleTag 
										color={tag.family.colors.color || null} 
										bgColor={tag.family.colors.bgColor} 
										textColor={tag.family.colors.textColor} 
										typeLabel={tag.family.label} 
										style={{border: "none"}}
									>
										{tag.text}
									</SimpleTag>
								</StyledTag>*/
							})
						}
					</StyledTagList>
				</StyledTagListWrapper>
			</StyledAside>
	})
)


const TabHighLight = ({children, options}) => {
	return <SimpleTag {...options} style={{display: "inline-flex", marginBottom: "4px", border: "none", padding: "3px 8px"}}>
		{children}
	</SimpleTag>
}

const Preview =inject('ExtractorStore')(
	observer(({ExtractorStore, ...props}) => {
		let searchWords = ExtractorStore.tags.map(tag => { return {word: tag.text, options:{ ...tag.family.colors, typeLabel: tag.family.label}}});
		return <StyledPreview>
				<HighlightText 
					searchWords={searchWords}
					textToHighlight={ExtractorStore.text}
					highlightTag={TabHighLight}
				/>
			</StyledPreview>
	})
)

const TextArea = inject('ExtractorStore', 'ModelsStore')(
	observer(({ExtractorStore, ModelsStore, ...props}) => {
		let { modelId } = useParams();
		return <div style={{display: "flex", flexDirection: "column", padding: "24px", flex: 1}}>
				<StyledTextArea>
					<h4>Test with your own text</h4>
					<textarea value={ExtractorStore.text} onChange={(e) => ExtractorStore.setText(e.currentTarget.value || "")}></textarea>		
				</StyledTextArea>

				{ 
				<Preview />
				}
					
				<StyledFooter>
					<Button 
						textColor={theme.green}
						backgroundColor={theme.green4}
						onClick={() =>ExtractorStore.extract(ModelsStore.API_URL, modelId)}
					>
						Extract
					</Button>
				</StyledFooter>
			</div>
	})
)

const Extractor = () => <Provider ExtractorStore={extractorStore}>
		<Paper style={{display: "flex", width: "100%", padding: "0", overflow: "hidden", minHeight: "600px"}}>
		<div style={{flex: 2, display: "flex", flexDirection: "column"}}>
			<TextArea />
		</div>
		<Separator style={{padding: "0", "margin": "12px 0"}} lineColor={theme.black4} direction={"right"}/>
		<Aside />
	</Paper>
</Provider>


export default Extractor;

