const modelApiDatas = [
	{
		family: ["classifier", "detector"],
		requestSentence: "To test make a POST request to the following URL:",
		url: '[API_URL]/model/[modelId]/categorize/',
		payloadSentence: 'This endpoint expects a JSON in the request body (or payload body). It must be an object with a text propertie. For example:',
		payloadSample: `{
	"text": "This is your test sentence"
}`,
		payloadSampleType: 'json',
		codes: [
			{
				language: "bash",
				label: "cURL",
				content: `curl -X GET \
  [API_URL]/model/[modelId]/categorize/J%27ai%20un%20soucis%20avec%20ma%20commande`
			},
			{
				language: "javascript",
				label: "Node.js",
				content: `const axios = require('axios');
const API_URL = "[API_URL]/model/[modelId]/categorize";
axios.get(\`\${API_URL}/J%27ai%20un%20soucis%20avec%20ma%20commande\`).then((response) => {
    console.log(response.data);
});`
			}	
		],
		responseSentence: `The response body is JSON formatted.
		A successful test will return a list of tags for the given text in parameter.
		For example for the previous request the response body will look like this:`,
		responseSample: `[
	{
		"tag":"Order_problem",
		"confidence":0.35720561558897196
	}
]`
	},
	{
		family: ["extractor"],
		requestSentence: "To extract make a POST request to the following URL:",
		url: "[API_URL]/extractor/[modelId]",
		payloadSentence: 'This endpoint expects a JSON in the request body (or payload body). It must be an object with a text propertie. For example:',
		payloadSample: `{
	"text": "Le chien est sorti dans Paris sous un ciel bleu"
}`,
		payloadSampleType: 'json',
		codes: [
			{
				language: "bash",
				label: "cURL",
				content: `curl -X POST \\
  [API_URL]/extractor/[modelId] \\
  -H 'Content-Type: application/x-www-form-urlencoded' \\
  -H 'cache-control: no-cache' \\
  -d text=Le%20chien%20est%20sorti%20dans%20Paris%20sous%20un%20ciel%20bleu`
			},
			{
				language: "javascript",
				label: "Node.js",
				content: `const axios = require('axios');
const API_URL = "[API_URL]/extractor/[modelId]";
axios({
	method: 'POST',
    url: API_URL,
    data: {text: your_sentence}
}).then((response) => {
    console.log(response.data);
});`
			}	
		],
		responseSentence: `The response body is JSON formatted.
		A successful extraction will return a list of tags for the given text in the data parameter.
		For example for the previous request the response body will look like this:`,
		responseSample: `{
	"families":["couleurs"],
	"datas":[
		{
			"family":"couleurs",
			"text":"Bleu",
			"offset":{"start":44,"end":48}
		}
	]
}`
	}
]

export default modelApiDatas;