import {decorate, observable, action, computed} from "mobx";
import tagFamilyDatas from '../../constants/tagFamilyDatas';
const axios = require('axios');

class Tag {
  constructor({text, family, offset}) {
    this.text = text;
    this.family = family;
    this.offset = offset;
  }
}
decorate(
  Tag, {
    text: observable,
    family: observable,
    offset: observable,
})


class Family {
  constructor({label}) {
    this.label = label;
  }

  get colors(){
    const familyData = tagFamilyDatas.find(family => family.label === this.label);
    return {...familyData};
  }

}
decorate(
  Family, {
    label: observable,
    colors: computed,
})


class ExtractorStore {
    text = "je suis sorti promener mon chien roux dans les rue de Paris sous un joli ciel bleu, quelques nuages blanc forment des petits lapin";
    families = [];
    tags = [];

    setText = (text) => {
      this.text = text;
    }

    extract = (API_URL, modelId) => {
      this.tags = [];
      this.families = [];

      axios({
          method: 'POST',
          url: `${API_URL}/extractor/${modelId}`,
          data: {text: this.text}
      }).then((response) => {
        response.data.families.map(obj => {
          this.families.push(new Family({label: obj}));
          return obj;
        });      

        response.data.datas.map(obj => {
          this.tags.push(new Tag({...obj, family: this.families.find(fam => fam.label === obj.family)}));
          return obj;
        }); 

      }).catch((error) => {
        console.log(error, "error");
      });
    } 
}
decorate(ExtractorStore, {
  text: observable,
  families: observable,
  tags: observable,
  setText: action,
  extract: action
});
const extractorStore = new ExtractorStore();

export {extractorStore};