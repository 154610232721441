import React, { Fragment } from 'react';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import history from './history';
import { Provider, inject, observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/App.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fas as pfas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import {ThemeProvider} from 'styled-components';
import theme from './theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import MuiTheme from './MuiTheme';

import Utils from './Auth/Utils';
import routes from './routes';
            
import modelFamilyDatas from './constants/modelFamilyDatas';

import GlobalStore from './stores/GlobalStore';

import Loader from './components/Loader/Loader';

library.add(fas, fal, pfas, fad);

let globalStore = new GlobalStore();

const PrivateRoute = inject('UserStore')(observer(({ UserStore, component: Component, componentProps = {}, ...rest }) =>  (
    <Route
        {...rest}
        exact
        strict
        render={(props) => {
            // Noone logged in => redirect to login
            if (Utils.isAuthenticated() !== true) return <Redirect
                to={{
                    pathname: "/login",
                    search: "?goto=" + escape(window.location.pathname),
                    state: { referrer: window.location.pathname },
                }}
            />;

            // // Email not verified => verify email page
            // if (UserStore.auth0datas.email_verified !== true) return <>{"verifyEmail"}</>;

            // // User has no Company => display contact information
            // if (!(UserStore.company && UserStore.company.id)) return <>{"NotaCustomer"}</>;

            //Redirection if modelType don't exist
            if(props.match.params && props.match.params.modelType && modelFamilyDatas.filter(family => family.title === props.match.params.modelType).length === 0){
                return <Redirect to={"/"}/>
            }

            // All good, display App
            return <Component {...props} {...componentProps} />;  
        }}
    />
)));

const UnauthenticatedRoute = ({ component: Component, componentProps = {}, ...rest }) => (
    <Route
        {...rest}
        exact
        strict
        render={(props) => {
            if (Utils.isAuthenticated !== true) return <Component {...props} {...componentProps}/>;
            return <Redirect to="/" />;
        }}
    />
);

const MainContainer = inject('UserStore')(observer(({UserStore, loading}) =>  {
    return <>
      { (loading && UserStore.loading) && <Loader style={{top: 0}}/> }
      { (!loading || !UserStore.loading) && <div className="main-container">
          <Router history={history}>
            <Switch>
              {routes.map(({ path, Component, componentProps = {}, unauthenticated = false, authenticated = true }, key) => {
                if(unauthenticated) return <UnauthenticatedRoute path={path} key={key} component={Component} componentProps={componentProps}/>;
                if(authenticated) return <PrivateRoute path={path} key={key} component={Component} componentProps={componentProps}/>;
                return <Route path={path} key={key} render={(props) => <Component {...props} {...componentProps}/>} />;
              })}
            </Switch>
          </Router>

          <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick={true}
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable={false}
              pauseOnHover={false}
              closeButton={false}
          />
        </div>
      }
    </>
  }));

export default class App extends React.Component {
  state = {
      loading: true
  };

  async componentDidMount() {
    window.toast = toast;
    this.fetchUser();
  }

  async fetchUser(){
    if (window.location.pathname.match(/^\/logout/)) {
        return this.setState({ loading: false });
    }

    this.setState({ loading: true }, () => {
        if (!localStorage.getItem('id_token')) {
            return this.setState({ loading: false });
        }

        globalStore.UserStore.fetchUser();
    });
  }

  render(){
    return <Fragment>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={MuiTheme}>
        <Provider
          GlobalStore={globalStore}
          UserStore={globalStore.UserStore}
          SpiderStore={globalStore.SpiderStore}
          ModelsStore={globalStore.ModelsStore}
          ModelCreatorStore={globalStore.ModelCreatorStore}
          UiStore={globalStore.UiStore}
        >
          <MainContainer loading={this.state.loading}/>
        </Provider>
      </MuiThemeProvider>
    </ThemeProvider>
    </Fragment>
  }
}
