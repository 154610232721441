import React from 'react';
import styled from 'styled-components';
import TextInput from '../TextInput/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyleTagInput = styled(TextInput)`
	border-radius: 32px;
	overflow: hidden;
	max-width: 400px;
	background-color: #fff;

	.rc-textinput,
	.rc-textinput-append{
		border: none;
		background-color: #fff;
		margin: 0;
		&:hover, &:focus{
			border: none;
			background-color: #fff;
			margin: 0;
		}
	}

	.rc-textinput{
		padding: 12px 24px;
		font-family: "Avenir Next Demi Bold";
	}

	.rc-textinput-append{
		padding: 12px;
	}

	.add-btn{
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background-color: ${props => props.theme.blue4};
		color: ${props => props.theme.blue1};

		&:hover{
			background-color: ${props => props.theme.blue5};
		}
	}
`;

const TagInput = ({onSubmit, ...props}) => {
	return <StyleTagInput
			onSubmit={onSubmit}
			{...props}
			placeholder={"Enter Tag Name ..."}
			append={
				<span className='add-btn' onClick={onSubmit}>
					<FontAwesomeIcon icon='plus'/>
				</span>
			}
		/>
}

export default TagInput;