import styled from 'styled-components';
import List from '@material-ui/core/List';

const SimpleList = styled(List)`
	.MuiListItem-root{
		border-radius: 6px;
		margin-bottom: 12px;
	    box-shadow: 0 1px 3px rgba(32,45,113,0.08), 0 1px 2px rgba(32,45,113,0.08);
	    background-color: #fff;
	}
`;
export default SimpleList;