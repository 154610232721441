import React from 'react';
import styled from 'styled-components';

const StyledListFilters = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
    flex-wrap: wrap;
	padding-top: 12px;
	padding-bottom: 6px;
	width: 100%;
    max-width: 800px;

    .filtersLeft{
    	button{
    		margin-right: 6px;

    		&:last-child{
    			margin-right: 0;
    		}
    	}

    	.MuiCheckbox-root{
    		margin-right: 6px;
    	}
    }

    .filtersRight{
	    display: flex;
	    align-items: center;

    	.orderSelect{
			&.MuiFormControl-marginDense{
				margin-top: 0;
				margin-bottom: 1px;
			}

    		.MuiOutlinedInput-inputMarginDense{
				padding-top: 6px;
				padding-bottom: 6px;
    		}

    		.MuiInputBase-input{
    			font-size: 13px;
    		}
    	}
    }
`;

const ListFilters = ({children, ...props}) => <StyledListFilters {...props}>
	{children}
</StyledListFilters>

export default ListFilters;