import React from 'react';
import { useParams } from "react-router-dom";
import { observer, inject} from 'mobx-react';
import Teach from '../components/Teach/Teach';
// import SelectWords from '../components/SelectWords/SelectWords';

const ModelDetailTeach = inject('ModelsStore')(
	observer(({ModelsStore, ...props}) => {
		let { modelId } = useParams();
		let currentModel = ModelsStore.modelById(modelId);
		// const [tag, setTag] = useState(null);
		
		return  <>
			{
				/*
				<SelectWords tag={tag || undefined}/>
				
				<span onClick={() => setTag({
					id: "tag1",
					label: "tag1",
					bgColor: "#ffecec",
					textColor: "#ff5451"
				})}>Tag 1</span>

				<span onClick={() => setTag({
					id: "tag2",
					label: "tag2",
					bgColor: "#e7fcf2",
					textColor: "#11cb72"
				})}>Tag 2</span>

				<span onClick={() => setTag({
					id: "tag3",
					label: "tag3",
					bgColor: "#eceeff",
					textColor: "#2e43e7"
				})}>Tag 3</span>
				*/
			}


			<Teach
				tags={currentModel.returned_values}
				onSubmit={(sentence, selectedTag) => {
					ModelsStore.teach(modelId, sentence, selectedTag);
				}}
			/>
		</>
	})
);

export default ModelDetailTeach;