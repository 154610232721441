const dataSourcesDatas = [
	{	
		type: "excel",
		name: "Excel File",
		icon: "file-excel",
		iconColor: "green",
		title: 'Upload an Excel file',
		description: 'Choose an Excel file that contains the text data that you will use to train your model.',
		accept: '.xlsx, .xls'
	},
	{
		type: "csv",
		name: "CSV",
		icon: "file-csv",
		iconColor: "blue",
		title: 'Upload a CSV file',
		description: 'Choose a CSV file that contains the text data that you will use to train your model.',
		accept: '.csv'
	},
	{
		type: "json",
		name: "Json",
		icon: "file-code",
		iconColor: "red",
		title: 'Upload a Json file',
		description: 'Choose an Json file that contains the text data that you will use to train your model.',
		accept: '.json'
	}
]

export const getDataSourceByType = (type) => {
	return dataSourcesDatas.find(datasource => datasource.type === type);
}

export default dataSourcesDatas;