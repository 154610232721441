import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Toast = ({message, type, withIcon = true, ...props}) => {

	const icon = {
		'success': ['fal', 'check'],
		'error': ['fal', 'times'],
		'warn': ['fal', 'exclamation'],
		'info': ['fal', 'lightbulb'],
		default: null
	}[type];
	console.log(icon, "icon");

	const formatedMessage = <>
		{withIcon && icon && <FontAwesomeIcon icon={icon} />}
		{message}	
	</>

	const className = `rcAlert ${type} ${props.className}`;

    switch (type) {
      case 'success':
        toast.success(formatedMessage, {
          ...props,
          className: className,
        });
        break;

      case 'error':
        toast.error(formatedMessage, {
          ...props,
          className: className
        });
        break;

      case 'warn':
        toast.error(formatedMessage, {
          ...props,
          className: className
        });
        break;

      case 'info':
        toast.error(formatedMessage, {
          ...props,
          className: className
        });
        break;

      default:
        toast(formatedMessage, {
          ...props,
          className: className
        });
        break;
    }
};
export default Toast;