import {decorate, observable, action} from "mobx";

class UiStore {

  drawerWidth = 300;
  drawerCloseWidth = 80;
  contentPushBySidebar = false;
  headerFixed = true;
  menuOpen = false;

  closeMenu = () => {
      this.menuOpen = false;
  };

  openMenu = () => {
      this.menuOpen = true;
  };

  toggleMenu = () => {
      this.menuOpen = !this.menuOpen;
  }
}

decorate(UiStore, {
  drawerWidth: observable,
  drawerCloseWidth: observable,
  contentPushBySidebar: observable,
  menuOpen: observable,
  headerFixed: observable,
  openMenu: action,
  closeMenu: action,
  toggleMenu: action
});

export default UiStore;