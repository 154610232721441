import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import {Paper} from '../Containers';
import Checkbox from '../Checkbox/Checkbox';
import Separator from '../Separator/Separator';

import Button from '@material-ui/core/Button';

const StyledTextArea = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: ${props => props.theme.black};
	font-weight: normal;
	margin-right: 12px;
	padding: 12px 0;
	height: 100%;

	textarea{
		border: 1px solid ${props => props.theme.black3};
	    overflow: auto;
	    outline: none;
	    resize: none;
	    width: 100%;
	    font-family: "Avenir Next";
	    font-size: 12px;
	    line-height: 20px;
	    padding: 12px;
	    flex-grow: 1;
	}
`;

const StyledTagsList = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: ${props => props.theme.black};
	font-weight: normal;
	margin-left: 12px;
	padding: 12px 0;
	height: 100%;

	.Classifier__TagsList__Header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: ${props => props.theme.black1};

		h4{
			margin-bottom: 0;
		}

		ul{	
			display: flex;
			align-items: center;
			justify-content: space-between;

			li{
				padding: 6px;
				cursor: pointer;

				svg{
					transition: transform all .3s ease-in-out;
				}
				
				&.inAddMode svg{
					transform: rotate(-45deg);
				}

				&:first-child{
					margin-right: 6px;
				}

				&:hover{
					color: ${props => props.theme.black};
				}
			}
		}
	}
	
	.Classifier__TagsList__List{
		flex: 1;
		margin-top: 12px;
	}
`

const StyledTag = styled.li`
	user-select: none;
	margin: 6px 0;
`

const TextArea = ({text, onChange}) => <StyledTextArea className="Classifier__Textarea">
	<textarea value={text} onChange={(e) => onChange(e.currentTarget.value)} />
</StyledTextArea>

const Tag = ({label, selected, onClick}) => {
	return <StyledTag>
		<Checkbox 
			checked={selected} 
			onChange={(val) => onClick(!selected)}
			radio={true}
		>
			{label}
		</Checkbox>
	</StyledTag>
}

const TagsList = ({tags, selectedTags, onClick}) => {

	return <StyledTagsList className="Classifier__TagsList"> 
		<div className="Classifier__TagsList__Header">
			<h4>Tags</h4>
		</div>
		<ul className="Classifier__TagsList__List">
			{
				tags.map((label, index) => {
					return <Tag 
						key={`tag-${index}`}
						index={index}
						label={label}
						selected={label === selectedTags}
						onClick={() => onClick(label)}
					/>
				})
			}
		</ul>
	</StyledTagsList>
}

const Teach = ({tags = [], onSubmit = () => {}}) => {
	const [sentence , setSentence] = useState("");
	const [selectedTags , setSelectedTags] = useState(null);

	return <Fragment>
			<Paper style={{display: "flex", width: "100%", minHeight: "600px"}}>
			<div style={{flex: 2}}>
				<TextArea text={sentence} onChange={(value) => setSentence(value)}/>
			</div>
			<Separator/>
			<div style={{flex: 1}}>
				{ 
					<TagsList tags={tags} selectedTags={selectedTags} onClick={(val) => setSelectedTags(val)}/> 
				}
			</div>
		</Paper>
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '12px 0', width: '100%'}} >
			<Button 
				color="secondary"
				variant="contained"
				size="large"
				onClick={() => onSubmit(sentence, selectedTags)}
				disabled={sentence.length < 2 || !selectedTags}
			>
				Teach
			</Button>
		</div>
	</Fragment>
}

export default Teach;

