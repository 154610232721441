import React from 'react';
import styled from 'styled-components';

const StyledGauge = styled.div`
	width: 100%;
	height: 100%;

	.Gauge{
	 	display: block;
	  	max-width: 80%;
	 	max-height: 250px;
	 	width: 100%;
	 	height: 100%;
	}

	.Gauge--wrapper{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.Gauge--circle {
	  stroke: ${props => props.theme[props.color]};
	  fill: none;
	  stroke-width: 2.8;
	  stroke-linecap: round;
	  animation: progress 1s ease-out forwards;
	}

	.Gauge--circle-helper{
	  stroke: ${props => props.theme.black4};
	  fill: none;
	  stroke-width: 2.8;
	}

	.Gauge--percent{
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
		width: 100%;
		text-align: center;
		font-family: "Avenir Next Bold";
		font-size: 30px;
		color: ${props => props.theme.black};
	}

	@keyframes progress {
	  0% {
	    stroke-dasharray: 0 100;
	  }
	}
`;

const Gauge = ({percent, color, style, ...props}) => <StyledGauge {...props} color={color}>
    	<div className="Gauge--wrapper" style={style}>
			<svg viewBox="0 0 36 36" className="Gauge">
			  <path className="Gauge--circle-helper"
			    strokeDasharray={`100, 100`}
			    d="M18 2.0845
			      a 15.9155 15.9155 0 0 1 0 31.831
			      a 15.9155 15.9155 0 0 1 0 -31.831"
			  />
			  <path className="Gauge--circle"
			    strokeDasharray={`${percent}, 100`}
			    d="M18 2.0845
			      a 15.9155 15.9155 0 0 1 0 31.831
			      a 15.9155 15.9155 0 0 1 0 -31.831"
			  />
			</svg>
			<span className="Gauge--percent">{`${percent}%`}</span>
    	</div>
    </StyledGauge>;

Gauge.defaultProps = {
	percent: 0,
	color: "blue1",
	style: {}
}

export default Gauge;