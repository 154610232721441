import React, {useState} from 'react';
import styled from 'styled-components';

import Code from '../Code/Code';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const URLDetail = ({id, metas, url, feedback = {}, advices = [], showBtnAddToFaq = true}) => {
	const [tabFeedback, setTabFeedback] = useState(0);

	const handleChangeTabFeedback = (event, newValue) => {
		setTabFeedback(newValue);
	};

	return <StyledUrlDetail>
		<URLDetailTitle>{url}</URLDetailTitle>
		<div style={{padding: "0 24px", display: "flex", flexDirection: "column"}}>
			<URLDetailSubTitle>MetaDatas</URLDetailSubTitle>
			{
				metas && <Metas>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Meta propertie</TableCell>
								<TableCell align="right">Meta content</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{
							metas.map((meta, index) => {
								return <TableRow key={`url-${id}-meta-${index}`}>
									<TableCell component="th" scope="row" style={{fontStyle: 'italic', fontWeight: 500}}>
										{meta.property}
									</TableCell>
									<TableCell align="right">{meta.content}</TableCell>
								</TableRow>
							})
						}
						</TableBody>
					</Table>
				</Metas>
			}
			{ !metas && <p style={{marginLeft: 24, fontSize: "0.875rem", lineHeight: 1.43}}>No metadatas found.</p> }

			{ metas && <>
				<URLDetailSubTitle>
					Advices
				</URLDetailSubTitle>

				<div className="feedbacks">
					<Tabs
						value={tabFeedback} 
						onChange={handleChangeTabFeedback}
						aria-label="simple tabs"
						variant="fullWidth"
						centered
						indicatorColor="primary"
					>
					    <Tab label="Simple" />
					    <Tab label="Advanced" />
					</Tabs>
					<TabPanel value={tabFeedback} index={0}>
						<Advices>
							{
								advices.map((advice, index) => <li key={`url-${id}-advices-${index}`}>{advice}</li>)
							}	
							{
								advices.length === 0 && "Everything seems good."	
							}		
						</Advices>
					</TabPanel>

					<TabPanel value={tabFeedback} index={1}>
						<div className="feedbacks-details">
							<Code 
								lineNumbers={false}
								showCopy={false}
								codes={[
									{
										language: "json",
										content: JSON.stringify(feedback.features, null, 2)
									}
								]}
							/>
						</div>
					</TabPanel>
				</div>
				{
					showBtnAddToFaq && <Button 
						color="primary"
						variant="contained"
						size="large"
						onClick={() => console.log("add to FAQs")}
						style={{margin: "24px auto"}}
					>
			    		Add to FAQs
			    	</Button>
		    	}
			</>
			}

		</div>

	</StyledUrlDetail>
}

const StyledUrlDetail = styled.div`
	display: flex;
	flex-direction: column;

	.feedbacks{
		position: relative;
		border: 1px solid rgba(224,224,224,1);

		.MuiTabs-root{
			border-bottom: 1px solid rgba(224,224,224,1);
		}

		.feedbacks-details{
			& > div{
				height: 100%;
			}

			.language-json{
				font-size: 12px;
				line-height: 18px;
			}
		}
	}
`

const URLDetailTitle = styled.div`
	position: sticky;
	top: 0;
	padding: 12px;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	color: #fff;
	background-color: ${props => props.theme.blue1};
`

const URLDetailSubTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 24px 0 12px;
	font-weight: 600;
	font-size: 16px;
	color: ${props => props.theme.blue1};
	text-transform: uppercase;
`

const Metas = styled(TableContainer)`
	.MuiTable-root{
		border-width: 1px 1px 0 1px;
		border-style: solid;
		border-color: rgba(224, 224, 224, 1)
	}
`

const Advices = styled.ul`
	padding: 24px 12px;
	font-size: 0.875rem;
	line-height: 1.43;

	li{
		position: relative;
		margin-bottom: 12px;

    	list-style: disc inside;

		&:last-child{
			margin-bottom: 0px;
		}
	}
`;

export default URLDetail;