import React, {useState} from 'react';
import { observer, inject} from 'mobx-react';
import Layout from '../layout/layout';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';

import {Header as PageHeader, Wrapper as PageWrapper} from '../components/Page';

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';


const StyledAddForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 800px;
	margin-top: 24px;

	& .MuiTextField-root{
		margin: 6px 0;
    	width: 100%;

    	.MuiFilledInput-root{
			background-color: white;
			border: 1px solid rgba(191,202,216,0.4);
			border-radius: 4px;
			margin: 1px;

			&:before{
				display: none;
			}

			&.Mui-focused{
				margin: 0px;
				border-width: 2px;
				border-style: solid;
				border-color: ${props => props.theme.blue1};

			}

            &.Mui-error{
				border-color: #ff5451;
            }

    	}

    	.MuiFilledInput-input:-webkit-autofill{
			-webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
    	}

    	.MuiFormLabel-root{
            color: ${props => props.theme.black2};

            &.MuiInputLabel-filled{
				transform: translate(12px, 22px) scale(1);
				&.MuiInputLabel-shrink{
					transform: translate(12px, 10px) scale(0.75);
				}
            }

            &.Mui-focused{
				color: ${props => props.theme.blue1};
            }

            &.Mui-error{
            	color: #ff5451;
            }
    	}

		@media (min-width: 600px) {
			margin: 6px;
			width: auto;
			flex: 1;

			&.url-field{
				flex: 2;
			}
		}

		@media (min-width: 960px) {
			&.url-field{
				flex: 3;
			}

			&.companyId-field{
				flex: 2;
			}
		}
	}

	& > div{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-bottom: 12px;

		@media (min-width: 600px) {
			flex-direction: row;
		}
	}
`;

const FieldsData = [
	{
		id: "url",
		label: "URL",
		className: "url-field",
		helper: null,
		required: true,
		type: "url",
		defaultValue: "http://176.31.127.193/rcog/"
	},
	{
		id: "companyId",
		label: "Company ID",
		className: "companyId-field",
		helper: "You can find this information in the backoffice",
		required: true,
		type: "text",
		defaultValue: "5cb98d4c366a9b00069120d5"
	}
];

const urlRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

const SpiderAdd = inject('SpiderStore')(
	observer(({SpiderStore, ...props}) => {
		const [newSite, setNewSite] = useState(Object.fromEntries([...FieldsData.map(field => {
			return [field.id, {value: field.defaultValue || '', error: null}];
		})]));

		const updateField = (id, value) => {
			setNewSite({...newSite, [id]: {...newSite[id], value: value}});
		}

		const validateField = (id, updateState = false) => {
			let fieldData = FieldsData.find(field => field.id === id);
			let fieldValue = newSite[id].value;
			let error = null;

			// if(fieldData.type === "url" && !urlRegex.test(fieldValue)){
			// 	error = "Invalid Url format";
			// }

			if(fieldData.required && (fieldValue === '' || fieldValue === null)){
				error = `${fieldData.label} is required`
			}

			if(updateState){
				setNewSite({...newSite, [id]: {...newSite[id], error: error}});
			}
			return error;
		}

		const validateForm = () => {
			let newState = {...newSite};
			FieldsData.map(field => {
				newState = {...newState, [field.id]: {...newState[field.id], error: validateField(field.id)}};
				return null;
			});
			let isValid = Object.values(newState).filter(field => field.error !== null).length < 1;
			setNewSite({...newState});
			return isValid;
		}

		return <Layout>
			<PageWrapper>
				<PageHeader 
					title="Add a site"
					surtitle="Spider"
				/>

				<StyledAddForm
					noValidate
					autoComplete="off" 
					onSubmit={(event) => {
						event.preventDefault();

						if(validateForm()){
							SpiderStore.addNewSite(Object.fromEntries(Object.entries(newSite).map(([id, field]) => [id, field.value]))).then((id) => {
								props.history.push(`/Spider/${id}/Detail`);
							});
						}
					}}
				>
					<div>
		  				
		  				{
		  					FieldsData.map(field => <TextField
			  					key={`field-${field.id}`}
			  					id={field.id}
			  					required={field.required}
			  					label={field.label}
			  					type={field.type}
			  					variant="filled"
			  					InputProps={{disableUnderline: true}}
			          			helperText={newSite[field.id].error || field.helper}
			  					className={field.className}
				    			value={newSite[field.id].value}
				    			onChange={(e) => updateField(field.id, e.currentTarget.value)}
				    			onBlur={(e) => validateField(field.id, true)}
				    			error={newSite[field.id].error ? true : false}
			  				/>)
		      			}
		      		</div>

					<Button 
						type="submit"
						color="primary"
						variant="contained"
						endIcon={<span><FontAwesomeIcon icon={['fad', 'star']} /></span>}
						startIcon={<span><FontAwesomeIcon icon={['fad', 'star']} /></span>}
					>
		        		Do the magic
		        	</Button>
		      	</StyledAddForm>

			</PageWrapper>

		</Layout>
	})
);

export default withRouter(SpiderAdd);