import {decorate, observable, computed, action} from "mobx";
import modelFamilyDatas from '../constants/modelFamilyDatas';
import { toast } from 'react-toastify';
import qs from 'qs';
const axios = require('axios');

class Model {
  constructor({id, name, tag, short_description, description, family, returned_values, icon, iconColor, iconBgColor, language, created_at}) {
    this.id = id;
    this.name = name;
    this.tag = tag;
    this.short_description = short_description;
    this.description = description;
    this.family = family;
    this.returned_values = returned_values;
    this.icon = icon;
    this.iconColor = iconColor;
    this.iconBgColor = iconBgColor;
    this.language = language;
    this.created_at = created_at;
    this.loading = false;
  }
}

class Tag {
  constructor({label, selected = false}) {
    this.label = label;
    this.selected = selected;
  }
}
decorate(
  Tag, {
    label: observable,
    selected: observable,
})

class Family {
  constructor({label, selected = false, color = null, bgColor = null, icon = null }) {
    this.label = label;
    this.selected = selected;
    this.color = color;
    this.bgColor = bgColor;
    this.icon = icon;
  }
}
decorate(
  Family, {
    label: observable,
    color: observable,
    bgColor: observable,
    icon: observable,
    selected: observable,
})

class ModelsStore {
    API_URL = "https://brainservice.reecall.co";
  	loading = true;
  	search = "";
    models = [];
    tags = [];
    families = [];

    constructor(){
      this.fetchModels();
    }

    get filteredModels() {
    	const selectedTags = this.tags.filter(tag => tag.selected === true).map(tag => tag.label);
      const selectedFamilies = this.families.filter(family => family.selected === true).map(family => family.label);
    	let models = this.models;
    	if(selectedTags.length > 0){
    		models = models.filter(model => selectedTags.includes(model.tag));
    	}

      if(selectedFamilies.length > 0){
        models = models.filter(model => selectedFamilies.includes(model.family));
      }

    	if(this.search !== ""){
    		models = models.filter(model => {
    			return model.description.search(this.search) !== -1 || model.name.search(this.search) !== -1 || model.family.search(this.search) !== -1;
    		});
    	}

    	return models;
    }

    modelById = (id) => {
    	return this.models.find(model => model.id === id);
    };

  	clearModels = () => {
   		this.models = [];
  	};

  	addModel = model => {
    	this.models.push(new Model(model));
  	};

  	clearTags = () => {
   		this.tags = [];
  	};

  	addTag = tag => {
  		if(!this.tags.find(obj => obj.label === tag.label)){
  			this.tags.push(new Tag(tag));
  		}
  	}

    clearFamilies = () => {
      this.families = [];
    };

    addFamily = family => {
      if(!this.families.find(obj => obj.label === family.label)){
        this.families.push(new Family(family));
      }
    }

    toggleFamilyByLabel = (familyName) => {
      this.families[this.families.findIndex(family => family.label === familyName)].selected = !this.families[this.families.findIndex(family => family.label === familyName)].selected;     
    }

    unselectAllFamilies = () => {
      this.families.map((family) => family.selected = false);
    }

    goToFamilyByLabel = (familyName) => {
      this.unselectAllFamilies();
      this.families[this.families.findIndex(family => family.label === familyName)].selected = true;     
    }

    showAlert = (payload) => {
        switch (payload.type) {
          case 'success':
            toast.success(payload.message, {
              className: 'rcAlert success'
            });
            break;
          case 'error':
            toast.error(payload.message, {
              className: 'rcAlert error'
            });
            break;
          default:
            toast(payload.message, {
              className: 'rcAlert success'
            });
            break;
        }
    }

  	fetchModels = () => {
  		this.clearTags();
  		this.clearModels();
  		axios.get(`${this.API_URL}/models`).then((response) => {
  			response.data.map(model => {
  				const familyData = modelFamilyDatas.find(family => family.title === model.family);
  				this.addModel({ ...model, icon: familyData.icon || null, iconColor: familyData.iconColor || null, iconBgColor: familyData.iconBgColor });
  				this.addTag({ label: model.tag });
          this.addFamily({ label: model.family, color: familyData.color || null, bgColor: familyData.iconBgColor || null, icon: familyData.icon });
  				return model;
  			});

  			this.loading = false;
		  }).catch((error) => {
			 console.log(error);
		  });
  	};

    fetchModelStats = async (modelId) => {
      const model = this.modelById(modelId);

      if(!model.hasOwnProperty("stats")){
        model.loading = true;
        await axios.get(`${this.API_URL}/model/${modelId}/stats`).then((response) => {
          model.stats = response.data;
          model.loading = false;
        }).catch((error) => {
         console.log(error);
        });
      }

      return model.stats;
    }

    teach = (modelId, sentence, tag) => {

      const url = `${this.API_URL}/model/${modelId}/teach`;
      const data = { text: sentence, tag: tag };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
      };

      axios(options).then((response) => {
        this.showAlert({message: "Successfull teach", type: "success"});
      }).catch((error) => {
        this.showAlert({message: "An error occured", type: "error"});
      });

    }

}
decorate(ModelsStore, {
  API_URL: observable,
  search: observable,
  loading: observable,
  models: observable,
  tags: observable,
  families: observable,
  modelById: action,
  clearModels: action,
  addModel: action,
  clearTags: action,
  addTag: action,
  clearFamilies: action,
  addFamily: action,
  toggleFamilyByLabel: action,
  goToFamilyByLabel: action,
  unselectAllFamilies: action,
  fetchModels: action,
  fetchModelStats: action,
  teach: action,
  showAlert: action,
  filteredModels: computed,
});

export default ModelsStore;