import React from 'react';
import { observer, inject} from 'mobx-react';
// import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Link as RouterLink } from "react-router-dom";
import { withRouter, useLocation, useParams, matchPath} from "react-router";

// import routes from '../../routes';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const StyledBreadcrumbs = withStyles({
	ol:{
		flexWrap: 'nowrap'
	}
})(Breadcrumbs);

const DEFAULT_MATCH_OPTIONS = { exact: true };
const NO_BREADCRUMB = 'NO_BREADCRUMB';

/**
 * This method was "borrowed" from https://stackoverflow.com/a/28339742
 * we used to use the humanize-string package, but it added a lot of bundle
 * size and issues with compilation. This 4-liner seems to cover most cases.
 */
const humanize = (str) => str
  .replace(/^[\s_]+|[\s_]+$/g, '')
  .replace(/[_\s]+/g, ' ')
  .replace(/^[a-z]/, (m) => m.toUpperCase());

/**
 * Renders and returns the breadcrumb complete
 * with `match`, `location`, and `key` props.
 */
const render = ({
  breadcrumb: Breadcrumb,
  match,
  location,
  ...rest
}): {
  match: { url: string },
  location: types.Location,
  key: string,
  breadcrumb: React.ReactNode
} => {
  const componentProps = { match, location, key: match.url, ...rest };

  return {
    ...componentProps,
    breadcrumb: typeof Breadcrumb === 'string'
      ? React.createElement('span', { key: componentProps.key }, Breadcrumb)
      : <Breadcrumb {...componentProps} />,
  };
};

const getDefaultBreadcrumb = ({
  currentSection,
  location,
  pathSection,
}) => {
  const match = matchPath(pathSection, { ...DEFAULT_MATCH_OPTIONS, path: pathSection })
    /* istanbul ignore next: this is hard to mock in jest :( */
    || { url: 'not-found' };

  return render({
    breadcrumb: humanize(currentSection),
    match,
    location,
  });
};

const getBreadcrumbMatch = ({
  currentSection,
  disableDefaults,
  excludePaths,
  location,
  pathSection,
  routes,
}) => {
  let breadcrumb;

  // Check the optional `excludePaths` option in `options` to see if the
  // current path should not include a breadcrumb.
  const getIsPathExcluded = (path) => matchPath(pathSection, {
    path,
    exact: true,
    strict: false,
  });
  if (excludePaths && excludePaths.some(getIsPathExcluded)) {
    return NO_BREADCRUMB;
  }

  // Loop through the route array and see if the user has provided a custom breadcrumb.
  routes.some(({ breadcrumb: userProvidedBreadcrumb, matchOptions, path, ...rest }) => {
    if (!path) {
      throw new Error('withBreadcrumbs: `path` must be provided in every route object');
    }

    const match = matchPath(pathSection, { ...(matchOptions || DEFAULT_MATCH_OPTIONS), path });


    // If user passed breadcrumb: null OR custom match options to suppress a breadcrumb
    // we need to know NOT to add it to the matches array
    // see: `if (breadcrumb !== NO_BREADCRUMB)` below.
    if ((match && userProvidedBreadcrumb === null) || (!match && matchOptions)) {
      breadcrumb = NO_BREADCRUMB;
      return true;
    }

    if (match) {
      // This covers the case where a user may be extending their react-router route
      // config with breadcrumbs, but also does not want default breadcrumbs to be
      // automatically generated (opt-in).
      if (!userProvidedBreadcrumb && disableDefaults) {
        breadcrumb = NO_BREADCRUMB;
        return true;
      }

      breadcrumb = render({
        // Although we have a match, the user may be passing their react-router config object
        // which we support. The route config object may not have a `breadcrumb` param specified.
        // If this is the case, we should provide a default via `humanize`.
        breadcrumb: userProvidedBreadcrumb || humanize(currentSection),
        match,
        location,
        ...rest,
      });
      return true;
    }
    return false;
  });

  // User provided a breadcrumb prop, or we generated one above.
  if (breadcrumb) {
    return breadcrumb;
  }

  // If there was no breadcrumb provided and user has disableDefaults turned on.
  if (disableDefaults) {
    return NO_BREADCRUMB;
  }

  // If the above conditionals don't fire, generate a default breadcrumb based on the path.
  return getDefaultBreadcrumb({
    pathSection,
    // include a "Home" breadcrumb by default (can be overrode or disabled in config).
    currentSection: pathSection === '/' ? 'Home' : currentSection,
    location,
  });
};

const getBreadcrumbs = ({ routes, location, params, options = {} }) => {
 	const matches = [];
  	const { pathname } = location;

  	pathname
    .split('?')[0]
    // Split pathname into sections.
    .split('/')
    // Reduce over the sections and call `getBreadcrumbMatch()` for each section.
    .reduce((previousSection, currentSection, index) => {
		// Combine the last route section with the currentSection.
		// For example, `pathname = /1/2/3` results in match checks for
		// `/1`, `/1/2`, `/1/2/3`.
		const pathSection = !currentSection ? '/' : `${previousSection}/${currentSection}`;
	  	// Ignore trailing slash or double slashes in the URL
		if (pathSection === '/' && index !== 0) {
			return '';
		}

		const breadcrumb = getBreadcrumbMatch({
			currentSection,
			location,
			pathSection,
			routes,
			...options,
		});

      	if (breadcrumb !== NO_BREADCRUMB) {
        	matches.push(breadcrumb);
      	}
      	return pathSection === '/' ? '' : pathSection;

	}, '');

  	return matches;
}

const withBreadcrumbs = (routes) => (WrapComponent) => {
	const flattenRoutes = (routes).reduce((arr, route) => {
	    if (route.routes) {
	      return arr.concat([route, ...flattenRoutes(route.routes)]);
	    }
	    return arr.concat(route);
	}, []);

	const withBreadcrumbs = withRouter(({props}) => {
		return <WrapComponent 
			breadcrumbs={
				getBreadcrumbs({
					routes: flattenRoutes,
					location: useLocation(),
					params: useParams(),
					options: {
						disableDefaults: false
					}
				})
			}
			{...props}
		/>
	});
	return withBreadcrumbs;
};
export {withBreadcrumbs};

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const RcBreadcrumbs = inject('ModelsStore')(
	observer(({ModelsStore, breadcrumbs, ...props}) => {
		return <>
			{
				!ModelsStore.loading && <>
				{
					breadcrumbs.length > 1 && <StyledBreadcrumbs aria-label="breadcrumb">
					{
						breadcrumbs.map(({ match, breadcrumb }, index) => {
					    	let last = index === breadcrumbs.length - 1;
							return last ? (
								<Typography noWrap color={last ? 'textPrimary' : 'inherit'} key={`${match.url}/`} >{breadcrumb}</Typography>
							) : (
								<LinkRouter color="inherit" to={match.url === "/" ? match.url : `${match.url}/`} key={match.url}>
									<Typography noWrap color={last ? 'textPrimary' : 'inherit'} >{breadcrumb}</Typography>
								</LinkRouter>
							);
						})
					}
					</StyledBreadcrumbs>
				}
				</>
			}
		</>
	})
);

export default RcBreadcrumbs;