import {decorate, observable, action} from "mobx";
import ReecallSdk from '@reecall/app_sdk';
import Utils from '../Auth/Utils';
import Lock from '../Auth/Lock';
import { getHighestRole } from '../utils';
import history from '../history';

class UserStore {

  loading = true;
  auth0datas = [];


  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  fetchUser = async(loggingIn = false) => {

    const rcsdk = new ReecallSdk(localStorage.getItem('id_token'), 'nope');
    this.loading = true;
    
    Lock.lock.getUserInfo(localStorage.getItem('access_token'), (err, profile) => {
      if (err) {
        Utils.clearLoginLocaleStorage();
        return null;
      }
      for (let key in profile) this.auth0datas[key] = profile[key];

      if (this.auth0datas["email_verified"] !== true) {
        console.log("verifyEmail");
        return null;
      }

      rcsdk.getMyself().then(user => {
        this.rcId = user.id;
        this.settings = user.settings || undefined;
        this.settings.activePhone = (this.settings.activePhone) ? this.settings.activePhone : [];
        this.settings.activeChat = (this.settings.activeChat) ? this.settings.activeChat : [];

        this.firstName = user.firstName || undefined;
        this.lastName = user.lastName || undefined;
        this.phone = user.phone || undefined;
        this.organizations = user.organizations;
        this.highestOrgRole = (user.organizations.length > 0) ? getHighestRole(user.organizations, "orgMemberRole") : 'user';

        rcsdk.getCompanies().then(orgs => {
            if (orgs.length > 0) {
              this.company = orgs[0].company;
              this.rootStore.SpiderStore.loadService(null, this.company.id);

              rcsdk.getMemberShip(this.company.id).then(memberShip => {
                  this.company.role = getHighestRole(memberShip, "role");
              }).catch(err => {
                  console.error(err);
              });

              this.loading = false;

              var queryParams = new URLSearchParams(window.location.search);
              if (loggingIn === true) {
                  history.push(queryParams.get('goto') || '/');
              }
            }
        }).catch(e => {
            console.log(e , "error get company");
        });
      }).catch(err => {
          console.error(err);
          Utils.clearLoginLocaleStorage();
      });
    })
  }

}

decorate(UserStore, {
  loading: observable,
  company: observable,
  fetchUser: action
});

export default UserStore;