import React, { Component } from 'react';
import Lock from './Lock';
import Utils from './Utils';
import { AUTH_CONFIG } from './auth0-variables';
import './auth.scss';

import { inject, observer } from 'mobx-react';

class Login extends Component {
    constructor(props) {
        super(props);
        this.onAuthenticated = this.onAuthenticated.bind(this);

        Utils.clearLoginLocaleStorage();
        this.queryParams = new URLSearchParams(this.props.location.search);
        this.options = {};
        this.onAuthenticated();
    }

    onAuthenticated() {
        Lock.lock.on('authenticated', authResult => {
            let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
            localStorage.setItem('access_token', authResult.accessToken);
            localStorage.setItem('id_token', authResult.idToken);
            localStorage.setItem('expires_at', expiresAt);

            this.props.UserStore.fetchUser(true);
        });
    }

    componentDidMount() {
        // Avoid showing Lock when hash is parsed.
        if (!/access_token|id_token|error/.test(this.props.location.hash)) {
            Lock.lock.show(this.options);
        }
    }

    render() {
        return <div id="login-container">
            <div id={AUTH_CONFIG.container} style={{ width: '100%', height: 'fit-content' }} />
        </div>;
    }
}
export default inject('UserStore')(observer(Login));
