import React, { useState } from 'react';
import styled from 'styled-components';

const StyledPaper = styled.div`
    padding: 12px 24px;
    border-radius: ${props => props.square ? '0' : '6px'};
    background-color: ${props => props.bgColor};

    ${props => props.theme.shadow(props.elevation)};
`;

const Paper = ({children, style, elevation, hoverable,  ...rest}) => {
    const [hover, setHover] = useState(false)
    return <StyledPaper 
            elevation={!hover ? elevation : 3}
            {...rest}
            style={style}
            onMouseEnter={() => hoverable && setHover(true)}
            onMouseLeave={() => hoverable && setHover(false)}
        >
        	{ children }
        </StyledPaper>
};

Paper.defaultProps = {
    elevation: 1,
    square: false,
    bgColor: "#fff",
    hoverable: false
}

export default Paper;