import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.div`
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;

    .rc-textinput {
        font-family: 'Avenir Next';
        color: ${props => props.theme.black};
        width: 100%;
        font-size: 16px;
        padding: ${props => props.small ? '5px 13px': '15px 13px'};

        border-top: 1px solid rgba(191, 202, 216, 0.4);
        border-left: 1px solid rgba(191, 202, 216, 0.4);
        border-bottom: 1px solid rgba(191, 202, 216, 0.4);
        border-right: ${props => props.append ? "0px" : "1px"} solid rgba(191, 202, 216, 0.4);       

        margin: 1px;
        margin-left: ${props => props.append ? "0px" : "1px"};
        border-top-left-radius: 6px;
        border-top-right-radius: ${props => props.append ? "0px" : "6px"};
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: ${props => props.append ? "0px" : "6px"};

        &.rc-error {
            margin: 1px;
            border: 1px solid ${props => props.theme.red2};
        }
        &:focus {
            margin: 0px;
            border: 2px solid ${props => props.theme.blue1};

            & + .rc-textinput-append:hover{
                border-left: none;
            }
        }
        &::placeholder {
            color: ${props => props.theme.black2};
        }
    }

    .rc-input-withoutRadius{
        border-radius: 0;
    }

    .rc-textinput-append{
        line-height: normal;
        border: 1px solid rgba(191, 202, 216, 0.4);
        padding: ${props => props.small ? '5px 13px': '15px 13px'};

        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        color: ${props => props.theme.black1};
        margin-right: 1px;
        cursor: pointer;

        &:hover{
            margin-right: 0px;
            color: ${props => props.theme.blue1};
            background-color: ${props => props.theme.black5};

            border-width: 2px;
            border-color: ${props => props.theme.blue1};
        }
    }
`;

const TextInput = ({value, onChange, placeholder, small, className, append, style, withoutRadius, onSubmit, ...props}) => {

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            onSubmit();
        }
    }

    return <StyledInput className={className} small={small} append={append !== null} style={style}>
        <input 
            className={`rc-input rc-textinput ${withoutRadius ? 'rc-input-withoutRadius' : ''}`} 
            onChange={onChange} 
            onKeyPress={handleKeyPress} 
            value={value} 
            {...props} 
            placeholder={placeholder}
        />
        {
            append && <div className='rc-textinput-append'>{append}</div>
        }
    </StyledInput>
}


TextInput.defaultProps = {
    value: "",
    onChange: () => {},
    placeholder: "",
    withoutRadius: false,
    small: false,
    append: null,
    onSubmit: () => {}
}

export default TextInput;