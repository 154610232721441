import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Prism from "prismjs";
import "prismjs/components/prism-bash.min.js";
import "prismjs/components/prism-json.min.js";
import "prismjs/components/prism-jsonp.min.js";
import "prismjs/plugins/line-numbers/prism-line-numbers.min";
import "prismjs/plugins/toolbar/prism-toolbar.min";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min";
import "../../scss/vendors/prism/prism.css";

const StyledCodeOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #272822;
    overflow: hidden;
    width: 100%;
`;

const StyledCodeInnerWrapper = styled.div`

    code[class*="language-"], pre[class*="language-"]{
        white-space: pre-wrap;
        width: 100%;
        word-break: break-all;
    }

    pre{
        display: none;
        &.active{
            display: block;
        }
    }

    pre[class*="language-"].line-numbers{
        padding-top: 0;
    }

    div.code-toolbar > .toolbar{
        right: .5em;

        a, button, span{
            color: ${props => props.theme.black4};
            cursor: pointer;

            &:hover, &:focus{
                color: #fff;
            }
        }
    }


`;

const StyledCodeHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px;
    background-color: ${props => props.theme.black};

    span{
        position: relative;
        color: ${props => props.theme.black2};
        padding: 8px 12px;
        cursor: pointer;

        &.active{
            color: ${props => props.theme.black4};

            &:before{
                content: "";
                position: absolute;
                bottom: 0;
                left: 6px;
                right: 6px;
                background-color: ${props => props.theme.black4};
                height: 3px;
            }
        }
    }
`;

const Code = ({codes, lineNumbers, showCopy}) => {

    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    useEffect(() => {
        Prism.plugins.Toolbar = true;
        Prism.plugins.CopyToClipboard = showCopy;
        Prism.plugins.LineNumbers = true;
        Prism.highlightAll();
    });

    return <StyledCodeOuterWrapper>
            
            {
                codes.length > 1 && <StyledCodeHeader>
                {
                    codes.map(({language, content, label}, index) => {
                        return <span 
                            key={`nav-${label}`}
                            className={currentTabIndex === index ? 'active' : ''}
                            onClick={() => setCurrentTabIndex(index)}
                        >
                            {label}
                        </span>
                    })
                }
                </StyledCodeHeader>
            }

            <StyledCodeInnerWrapper>
            {
                codes.map(({language, content, label}, index) => {
                    return <pre className={`code-block ${currentTabIndex === index ? 'active' : ''} ${lineNumbers ? "line-numbers" : ""}`} key={`code-${label}`}>
                        <code className={`language-${language}`}>
                            { content }
                        </code>
                    </pre>
                })
            }
            </StyledCodeInnerWrapper>
            
        </StyledCodeOuterWrapper>
}

Code.defaultProps = {
    lineNumbers: true,
    codes: [],
    showCopy: true
}

export default Code;


