import qs from 'qs';
import ReecallSdk from '@reecall/app_sdk';
const axios = require('axios');

const error = message => error => {
    const err = new Error(message);
    err.error = error;
    throw err;
}

const crawlerUrl = "https://spider.reecall.co/crawl";

class SpiderService {
    
    constructor(token, companyId) {
        this.rcsdk = new ReecallSdk(token, companyId);
    }

    startCrawl = async(url, companyId) => {
        const requestBody = {
          url: url,
          company_id: companyId
        };

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };

        return axios.post(crawlerUrl, qs.stringify(requestBody), config)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            error("An error occurred during the crawl. Please retry later.");
        });

    }

    getAllSites = async() => {
        return this.rcsdk.getAllSites();
    }

    getSiteById = async(siteId) => {
        return this.rcsdk.getSiteById(siteId);
    }

    getURLsBySiteId = async(siteId) => {
        return this.rcsdk.getURLsBySiteId(siteId);
    }

    countURLsBySiteId = async(siteId) => {
        return this.rcsdk.countURLsBySiteId(siteId);
    }

    getStatusBySiteId = async(siteId) => {
        return this.rcsdk.getStatusBySiteId(siteId);
    }

    addToFaq(data){
        return this.rcsdk.addToFaq(data);
    }

}

export default SpiderService;