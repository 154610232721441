import React, {Fragment} from 'react';
import { useParams } from "react-router-dom";
import { observer, inject} from 'mobx-react';
import styled from 'styled-components';
import Separator from '../components/Separator/Separator';
import SimpleTag from '../components/SimpleTag/SimpleTag';
import Code from '../components/Code/Code';
import modelApiDatas from '../constants/modelApiDatas';

const StyledAPI = styled.div`
	.API-info-data{
		.API-info-data-row{
			display: flex;
			justify-content: space-between;
		}
	}

	h3{
		margin-top: 30px;
		margin-bottom: 12px;
	}

	p{
		margin: 18px 0 4px;
		white-space: pre-line;

		&:first-child{
			margin-top: 0;
		}
	}

	.tagsList{
		display: flex;
		flex-wrap: wrap;

		& > span{
			margin-right: 12px;
			border: none;
			margin-bottom: 8px;
		}
	}

	.API-info-data{
		margin: 12px 0;

		.API-info-data-row{
			.API-info-data-col{
				display: flex;

				&:first-child{
					flex: 6;
				}

				&:last-child{
					flex: 4;
				}
			}
		}
	}

	.API-info-data-label{
		font-weight: 600;
		margin-right: 12px;
	}

	.API-info-data-value{
		color: ${props => props.theme.black1};

		&.highlight{
			color: ${props => props.theme.blue1};
		}
	}
`;

const replacePlaceholders = (str, params) => {
	let newStr = str;
	params.forEach((param) => {
		newStr = newStr.replace(param.name, param.value);
	})
	return newStr;
}

const ModelDetailAPI = inject('ModelsStore')(
	observer(({ModelsStore, ...props}) => {
		let { modelId } = useParams();
		let currentModel = ModelsStore.modelById(modelId);

		const APIDatas = modelApiDatas.find(obj => obj.family === currentModel.family || obj.family.includes(currentModel.family))

    	return <StyledAPI>
	    		<h2>API</h2>
	    		<Separator type="horizontal" />

	    		<div className='API-info-data'>
	    			<div className="API-info-data-row">
	    				<div className="API-info-data-col">
	    					<span className="API-info-data-label">Model ID :</span>
	    					<span className="API-info-data-value highlight">{currentModel.id}</span>
	    				</div>
	    				<div className="API-info-data-col">
	    					<span className="API-info-data-label">Created :</span>
	    					<span className="API-info-data-value">{currentModel.created_at || 'N/A'}</span>
	    				</div>
	    			</div>

	    			<div className="API-info-data-row">
	    				<div className="API-info-data-col">
	    					<span className="API-info-data-label">Language :</span>
	    					<span className="API-info-data-value">{currentModel.language}</span>
	    				</div>

	    				<div className="API-info-data-col">
	    				</div>
	    			</div>
	    		</div>

	    		<h3>Tags</h3>
	    		<div className='tagsList'>
	    			{
	    				currentModel.returned_values.map(returned_value => <SimpleTag 
	    					color="blue"
	    					fullRounded
	    					key={`skill-${returned_value}`}
    					>{returned_value}</SimpleTag>)
    				}
	    		</div>

	    		<h3>API Request</h3>

	    		<div>
	    			<p>{APIDatas.requestSentence}</p>
	    			<Code 
	    				lineNumbers={false}
	    				codes={[
							{
								language: "none",
								//`${ModelsStore.API_URL}/model/${currentModel.id}/test/your_sentence`
								content: APIDatas.url.replace('[API_URL]', ModelsStore.API_URL).replace('[modelId]', currentModel.id)
							}
						]}
					/>
					{
						APIDatas.payloadSentence && <p>{APIDatas.payloadSentence}</p>
					}
					{
						APIDatas.payloadSample && <Code 
		    				lineNumbers={false}
		    				codes={[
								{
									language: APIDatas.payloadSampleType,
									content: APIDatas.payloadSample
								}
							]}
						/>
					}
    			</div>

				{
					APIDatas.codes && <Fragment>
			    		<h3>Code examples</h3>

			    		<div>
							<Code 
								codes={[...APIDatas.codes.map((code) => { 
										return {
											language: code.language,
											label: code.label,
											content: replacePlaceholders(code.content, [
												{name: "[API_URL]", value: ModelsStore.API_URL},
												{name: "[modelId]", value: currentModel.id},
											])
										}
								})]}
							/>
		    			</div>
	    			</Fragment>
				}

				{
					APIDatas.responseSentence && <Fragment>
			    		<h3>API Response</h3>

			    		<div>
			    			<p>{APIDatas.responseSentence}</p>
			    			{
								APIDatas.responseSample && <Code 
				    				lineNumbers={true}
				    				codes={[
										{
											language: "json",
											content: APIDatas.responseSample
										}
									]}
								/>
							}
			    		</div>
		    		</Fragment>
				}

	    	</StyledAPI>
	})
);
export default ModelDetailAPI;