import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject} from 'mobx-react';
import styled from 'styled-components';
import TextInput from '../components/TextInput/TextInput';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const StyledTable = styled.ul`
	overflow: auto;
	width: 100%;
	margin-bottom: 12px;

	li{
		position: relative;
		border-radius: 6px;
		background-color: #fff;
	    color: ${props => props.theme.black};
	    font-size: 14px;
	    width: 100%;
	    margin-bottom: 12px;
	    box-shadow: 0 1px 3px rgba(32,45,113,0.08), 0 1px 2px rgba(32,45,113,0.08);
	    display: flex;

	    .rc-textinput{
	    	background-color: #fff;
	    	border: 1px solid transparent;
	    	border-radius: 6px 0 0 6px;
	    }

	    .selectWrapper{
			min-width: 240px;
			.MuiInputBase-root{
				border-radius: 0;
			}

			.MuiOutlinedInput-notchedOutline{
				border-width: 0px;
			}

			.placeholder{
				color: #bfcad8;
			}
	    }

	    .deleteButton{
	    	flex-shrink: 0;
	    }
	}
`;

const ModelCreatorCustom = inject('ModelCreatorStore')(
	observer(({ModelCreatorStore, ...props}) => {
  		const bottomRef = useRef(null);
  		const [autoFocus, setAutoFocus] = useState(false);

		useEffect(() => {
			if(ModelCreatorStore.datasetRows.length === 0){
				ModelCreatorStore.addRow();
			}
		}, [ModelCreatorStore, ModelCreatorStore.datasetRows.length]);

		const addRow = () => {
			setAutoFocus(true);
			// if(ModelCreatorStore.datasetRows[ModelCreatorStore.datasetRows.length - 1].text !== ""){
				ModelCreatorStore.addRow();
				setTimeout(() => bottomRef.current && bottomRef.current.scrollIntoView({ behavior: 'smooth' }), 10);
			// }
		};

		return <>
			<StyledTable>
				{
					ModelCreatorStore.datasetRows.map(({id, text, tag}) => {
						return <li key={`datasetRow-${id}`}>
							<TextInput 
								value={text}
								autoFocus={ModelCreatorStore.creationType === "custom" || autoFocus}
								onChange={(e) => {
									ModelCreatorStore.setTextByRowId(id, e.currentTarget.value);
								}}
								onKeyPress={(e) => {
									if(e.key === 'Enter'){
										addRow();
									}
								}}
								withoutRadius
								placeholder={"Your sentence"}
							/>

					        
							<FormControl variant="outlined" margin="none" className={"selectWrapper"}>
								<Select
									labelId={`select-text-${id}`}
									id="demo-simple-select-outlined"
									displayEmpty
									autoWidth
									value={tag || ''}
									onChange={event => ModelCreatorStore.setTagByRowId(id, event.target.value)}
									className={!tag ? 'placeholder' : ''}
								>
									<MenuItem value="">
										<em>No Tag</em>
									</MenuItem>
									{
										ModelCreatorStore.tags.map(oTag => <MenuItem key={`tag-${oTag.id}`} value={oTag.label}>{oTag.label}</MenuItem>)	
									}
								</Select>
							</FormControl>

					        <Button 
					        	aria-label="delete"
					        	className="deleteButton"
					        	disableRipple
					        	onClick={() => ModelCreatorStore.removeTextByRowId(id)}
				        	>
					          <RemoveCircleOutlineIcon fontSize="default"/>
					        </Button>
					        
						</li>
					})
				}
			</StyledTable>

			<div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%'}}>
				<Button 
					color="secondary"
					variant="contained"
					onClick={() => addRow()}
					size="large"
				>
	        		ADD ROW
	        	</Button>
				<Button 
					color="primary"
					variant="contained"
					onClick={() => addRow()}
					size="large"
					style={{marginLeft: 12}}
				>
					<Link to={"/ModelCreator/Name"} >
		        		Continue
		        	</Link>
	        	</Button>
        		<div ref={bottomRef} style={{marginTop: 24}}/>
			</div>

		</>
	})
);

export default ModelCreatorCustom;