import {decorate, observable, computed, action, runInAction} from "mobx";
import SpiderService from '../services/spiderService';
import {lookup} from '../utils';
const shortid = require('shortid');

let spiderService;

const crawlingState = {
  'processing': {
    icon: ["fal", "spider"],
    label: "Crawl in progress",
    sentence: "The website is currently being scanned by the crawler."
  },
  'pending': {
    icon: ["fal", "pause"],
    label: "Awaiting analysis",
    sentence: "Waiting for the analysis service. When available, a metadata analysis will be performed."
  },
  'analysing': {
    icon: ["fal", "brain"],
    label: "Analysis in progress",
    sentence: "Metadata Analysis is in progress. This allows us to provide you with advice regarding the quality of this metadata"
  },
  'done': {
    icon: ["fal", "check-circle"],
    label: "analysis completed",
    sentence: "Urls scanning and Metadata analysis are complete."
  }
};

export const URLsSort = {
  'score-DESC': "Scores - Good to Bad",
  'score-ASC': "Scores - Bad to Good",
  'question-ASC': "Question - A to Z",
  'question-DESC': "Question - Z to A",
  'url-ASC': "URLs - A to Z",
  'url-DESC': "URLs - Z to A",
};

export const SitesSort = {
  'originalUrl-ASC': "URLs - A to Z",
  'originalUrl-DESC': "URLs - Z to A",
};

class Url {
  constructor({url = "", metas = null, feedback = {features: {}, flag: 'bad', score: 0}}) {
    this.id = shortid.generate();
    this.url = url;
    this.metas = metas;
    this.question = metas && metas.find(meta => meta.property === "voice:search").content;
    this.feedback = feedback;
  }

  get advices(){
    let advices = [];
    if(this.feedback === null) return [];
    let features = this.feedback.features;
    if(features.ans_too_short) advices.push("Your response seems too short.");
    if(features.complicated_words_ratio > 0.2) advices.push("Your response contains too many complicated words.");
    if(features.estimated_answer_time > 10) advices.push("The duration of your response is too long.");
    if(features.estimated_answer_time < 1) advices.push("The duration of your response is too short.");
    return advices;
  }
}
decorate(
  Url, {
    id: observable,
    url: observable,
    metas: observable,
    question: observable,
    feedback: observable,
    advices: computed,
})

class Site {
  constructor({id = null, title = "", companyId = null, originalUrl = "", urls = [], status = "processing", date = null}) {
    this.id = id || shortid.generate();
    this.title = title;
    this.companyId = companyId;
    this.originalUrl = originalUrl;
    this.urls = urls;
    this.status = status;
    this.date = date;
    this.urlsLoaded = false;
    this.filter = 'all';
    this.scoreFilters = ['good', 'warning', 'bad'];
    this.orderFilter = 'score-DESC';
    this.selectedUrls = [];
  }

  fetchURLs = async () => {
    if(this.status === "done"){
      spiderService.getURLsBySiteId(this.id).then(urls => {
        runInAction(() => {
          this.urls = urls.map((url) => new Url({
            metas: (lookup(url, "data_page.question") && lookup(url, "data_page.answer")) ? [
              {property: 'voice:search', content: lookup(url, "data_page.question")},
              {property: 'voice:description', content: lookup(url, "data_page.answer")}
            ] : null,
            question: lookup(url, "data_page.question"),
            feedback: Object.keys(lookup(url, "data_score")).length > 0 ? lookup(url, "data_score") : {features: {}, flag: 'bad', score: 0},
            ...url
          }));
          this.urlsLoaded = true;
        });
      });
    }
  };

  fetchStatus = async () => {
    spiderService.getStatusBySiteId(this.id).then(status => {
        runInAction(() => {
          this.status = status;
          if(status === "done"){
            this.fetchURLs();
          }
          return this.status;
        });
    })
  }

  addSelectedUrlsToFaq = async() => {
    let faqs = this.selectedUrls.map(urlId => {
      let url = this.getURLById(urlId);
      return {
        question: url.metas.find(meta => meta.property === "voice:search").content,
        answer: url.metas.find(meta => meta.property === "voice:description").content,
        url: url.url,
        companyId: this.companyId
      }
    });

    let faqsPromises = [];
    faqs.forEach((faq) => {
        faqsPromises.push(spiderService.addToFaq(faq));
    });

    return Promise.all([...faqsPromises]).then((data) => {
      this.selectedUrls = [];
      return true;
    }).catch(err => {
      console.log(err, "err promise All Faq")
      throw err;
    });
  }

  getURLById(id){
    return this.urls.find(url => url.id === id);
  }

  get totalUrls(){
    return this.urls.length;
  }

  get totalUrlsWithMeta(){
    return this.urls.filter(url => url.metas).length;
  }

  get totalUrlsWithoutMeta(){
    return this.urls.filter(url => !url.metas).length;
  }

  get filteredUrls(){
    let filteredUrls = [];
    let [orderType, orderDir] = this.orderFilter.split("-");
    let orderPath = "";
    switch(orderType){
      case "score":
        orderPath = "feedback.score";
        break;

      default: 
        orderPath = orderType;
        break;
    }

    filteredUrls = this.urls.filter(url => {
      if(this.filter === 'withMetas' && !url.metas){
        return false;
      }
      if(this.filter === 'withoutMetas' && url.metas){
        return false;
      }
      return true;
    });

    filteredUrls = filteredUrls.filter(url => this.scoreFilters.indexOf(url.feedback.flag) !== -1);

    filteredUrls.sort((a, b) => {

        if(lookup(a, orderPath) === null) return 1;
        if(lookup(b, orderPath) === null) return -1;

        if(orderDir === "DESC"){
          if (lookup(a, orderPath) > lookup(b, orderPath)) return -1;
          if (lookup(b, orderPath) > lookup(a, orderPath)) return 1;
          return 0;
        } else {
          if (lookup(a, orderPath) < lookup(b, orderPath)) return -1;
          if (lookup(b, orderPath) < lookup(a, orderPath)) return 1;
          return 0;
        }
    });

    return filteredUrls;
  }

  get crawlingStateLabel(){
    return crawlingState[this.status || "processing"].label;
  }

  get crawlingStateSentence(){
    return crawlingState[this.status || "processing"].sentence;
  }

  get crawlingStateIcon(){
    return crawlingState[this.status || "processing"].icon;
  }

}
decorate(
  Site, {
    id: observable,
    title: observable,
    companyId: observable,
    originalUrl: observable,
    urls: observable,
    status: observable,
    date: observable,
    filter: observable,
    scoreFilters: observable,
    orderFilter: observable,
    urlsLoaded: observable,
    selectedUrls: observable,
    totalUrls: computed,
    totalUrlsWithMeta: computed,
    totalUrlsWithoutMeta: computed,
    crawlingStateLabel: computed,
    crawlingStateSentence: computed,
    crawlingStateIcon: computed,
    filteredUrls: computed,
    fetchUrls: action,
    getURLById: action,
    addSelectedUrlsToFaq: action
})

class SpiderStore {
  sites = [];
  sitesLoaded = false;
  orderFilter = 'originalUrl-ASC';

  loadService = (token = 'hqGd00JovQwPG1ak7OYXBdPruLo3MfH6egdaNAzp4cMlo0P8jYrk15SasugsKb0Z', companyId = null) => {
    spiderService = new SpiderService(localStorage.getItem('id_token') || token, companyId || 'noorg');
  }

  getAllSites = async () => {
      if(!this.sitesLoaded){
        spiderService.getAllSites().then(sites => {
          runInAction(() => {
              this.sites = sites.map((site) => new Site({originalUrl: site.url, title: lookup(site, 'extra_data.title'), date: site.updated_at, ...site}));
              this.sitesLoaded = true;
          });
        })
        .catch(err => console.log(err, "error"));
      }
  };

  getSiteById = async (id) => {
      let site = this.sites.find(site => site.id === id);
      if(site){
        if(site.status === "done"){
          site.fetchURLs();
        }
        return site;
      } else {
        spiderService.getSiteById(id).then(site => {
            runInAction(() => {
                const currentSite = new Site({originalUrl: site.url, title: lookup(site, 'extra_data.title'), date: site.updated_at, ...site});
                currentSite.fetchURLs();
                this.sites.push(currentSite);
            });
        })
      }
  };

  get filteredSites(){
    let filteredSites = [...this.sites];
    let [orderType, orderDir] = this.orderFilter.split("-");
    let orderPath = orderType;

    filteredSites.sort((a, b) => {
        if(orderDir === "DESC"){
          if (lookup(a, orderPath) > lookup(b, orderPath)) return -1;
          if (lookup(b, orderPath) > lookup(a, orderPath)) return 1;
          return 0;
        } else {
          if (lookup(a, orderPath) < lookup(b, orderPath)) return -1;
          if (lookup(b, orderPath) < lookup(a, orderPath)) return 1;
          return 0;
        }
    });

    return filteredSites;
  }

  addNewSite = async(newSiteDatas) => {
      try {
        const siteId = await spiderService.startCrawl(newSiteDatas.url, newSiteDatas.companyId);
        let newSite = new Site({id: siteId, originalUrl: newSiteDatas.url, ...newSiteDatas});
        this.sites.push(newSite);
        return newSite.id;
      } catch (error) {
        console.log(error, "error addNewSite");
      }

  }
}

decorate(SpiderStore, {
  sites: observable,
  sitesLoaded: observable,
  orderFilter: observable,
  filteredSites: computed,
  addNewSite: action
});

export default SpiderStore;