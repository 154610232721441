import React, {useState, useEffect} from 'react';
import { observer, inject} from 'mobx-react';
import { withRouter } from "react-router";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {useInterval} from '../customHooks';
import {URLsSort} from '../stores/spiderStore'; 
import Layout from '../layout/layout';
import blue_banner from '../blue_banner.svg';

import theme from '../theme';
import RcButton from '../components/Button/Button';
import URLDetail from '../components/Spider/URLDetail';
import ListFilters from '../components/Spider/ListFilters';
import SimpleList from '../components/Lists/SimpleList';
import {Header as PageHeader, Wrapper as PageWrapper} from '../components/Page';
import Paper from '../components/Containers/Paper';
import Loader from '../components/Loader/Loader';
import Toast from '../components/Toast/Toast';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const StyledLoader = styled(Loader)`
	background-color: transparent;
`;

const StyledURLsList = styled(SimpleList)`
	&.MuiList-root{
		width: 100%;
		max-width: 800px;
	}

	.MuiListItem-root{
		cursor: pointer;
	}

	.MuiListItemIcon-root{
		justify-content: center;
	}

	.MuiListItemSecondaryAction-root{
		right: 25px;
	}

	.emptyList{
		padding: 24px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const StyledFeedback = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${props => ({
		good: theme.green,
		warning: theme.orange,
		bad: theme.red,
	})[props.flag]};
	border-radius: 50%;
	color: #fff;
    width: 16px;
    height: 16px;
    line-height: normal;
`;

const StyledButtonsList = styled.div`
	width: 100%;
    max-width: 800px;
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	button{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		flex: 1;
		margin: 6px 0;

		em{
			margin-left: 6px;
			font-size: 11px;
		}
	}

	@media (min-width: 768px) {
		flex-direction: row;

		button{
			margin: 0 6px;

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}
`;

const BannerButton = styled(RcButton)`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;

	background-image: url(${blue_banner});
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    transition: all 0.15s;
    filter: grayscale(100%);

    &.selected{
    	filter: grayscale(0%);
    }

    &:hover{
    	text-decoration: none;
    	box-shadow:  0 3px 10px 0 rgba(68, 78, 90, .24);
    	transform: scale(0.98);
    }

    .customButton-total{
		width: calc(100% + 2px);
		margin-left: -1px;
		font-size: 42px;
    }
`;

const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paper{

  		@media (min-width: 768px) {
			width: 500px;
		}

  		@media (min-width: 992px) {
			width: 700px;
  		}
	}
`;


const StyledCrawlingState = styled(Paper)`
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 42px;
	padding: 0;
    overflow: hidden;

    .crawlingState{
    	width: 100%;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    }

    .crawlingStateInfos{
    	width: 100%;
  		display: flex;
  		flex-direction: column;
  		align-items: center;
  		justify-content: center;
  		padding: 24px;
		color: ${props => props.theme.black};
    }

    svg{
	    font-size: 40px;
	    margin-bottom: 12px;
		color: ${props => props.theme.blue};
    }

    hr{
	    border: 0px;
	    border-top: 1px solid ${props => props.theme.black4};
	    width: 100%;
    }

	.crawlingStateTitle{
		font-size: 18px;
	    line-height: normal;
	    font-weight: 600;
	    text-transform: uppercase;
	    margin-bottom: 12px;
	}

	.crawlingStateDetail{
		margin-top: 6px;
		color: ${props => props.theme.black1};
		text-align: center;

		b{
			font-weight: 600;
		}
	}
`;

const StyledLinearProgress = styled(LinearProgress)`
	width: 100%;
	max-width: 800px;
`;

const StyledListFilters = styled(ListFilters)`
    border-top: 1px solid ${props => props.theme.black3};
    margin-top: 24px;

	.filtersLeft{
    	padding-left: 17px;
	}

    .filtersRight{
    	.scoreFilter{
			background-color: transparent;
			margin-right: 12px;

			.MuiToggleButtonGroup-grouped{
				margin: -1px 2px 0;
				padding: 1px 8px 0;
			    &:not(:first-child){
			      	border-radius: 4px;
			      	border-left: 1px solid rgba(0, 0, 0, 0.12);
			    }
			    &:first-child{
			      	border-radius: 4px;
			    }
			    height: 31px;

				&.Mui-focusVisible{
					border: 2px solid #475cff;
					&:not(.Mui-selected){
						margin-left: 1px;
						margin-right: 1px;
					}
				}
			}

			.MuiToggleButton-root.Mui-selected{
			    border: 1px solid rgba(0, 0, 0, 0);
				background-color: ${props => props.theme.blue1};
		    	color: white;
			    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
			}

			.MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected{
	    		margin-left: 2px;
			}

	    	.MuiToggleButton-label{
	    		span{
	    			&:first-child{
	    				height: 10px;
	    				width: 10px;
	    			}
	    			&:last-child{
	    				margin-left: 6px;
	    				text-transform: capitalize;
	    				display: none;

						@media (min-width: 992px) {
	    					display: block;
						}
	    			}
	    		}
	    	}

	    /*	.MuiToggleButtonGroup-grouped{
				border: 1px solid rgba(0, 0, 0, 0);
				margin: -1px 2px 0;
				padding: 1px 8px 0;
			    &:not(:first-child){
			      	border-radius: 4px;
					border-left: 1px solid rgba(0, 0, 0, 0);
			    }
			    &:first-child{
			      	border-radius: 4px;
			    }
			    height: 31px;
			}

			.MuiToggleButton-root, .MuiToggleButton-root.Mui-selected{
				&.Mui-focusVisible{
					border: 2px solid #475cff;
					margin-right: 1px;
					margin-left: 1px;
				}
			}

			.MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected{
	    		margin-left: 2px;

				&.Mui-focusVisible{
					margin-left: 1px;
				}
			}

			.MuiToggleButton-root.Mui-selected{
				border: 1px solid rgba(0, 0, 0, 0.23);
				background-color: transparent;

				&:hover{
					border-color: #444e5a;
				}
			}

			.MuiToggleButton-label{
	    		span{
	    			&:first-child{
	    				height: 10px;
	    				width: 10px;
	    			}
	    			&:last-child{
	    				margin-left: 6px;
	    				text-transform: capitalize;
	    				display: none;

						@media (min-width: 992px) {
	    					display: block;
						}
	    			}
	    		}
	    	}*/


    	}
    }
`;

const StyledCircularProgress = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
`;

const UrlRow = ({id, url, metas, flag, checked, onCheck, onClick}) => <ListItem>
	<ListItemIcon>
  		<Checkbox
			edge="start"
			checked={checked}
			inputProps={{ 'aria-labelledby': url }}
			onChange={onCheck}
			color="primary"
			disabled={metas ? false : true}
  		/>
      	
    </ListItemIcon>
	<ListItemText 
		onClick={onClick}
		primary={metas ? metas.find((meta) => meta.property === 'voice:search').content : 'N/A'}
		secondary={url}
	/>
	<ListItemSecondaryAction>
		<StyledFeedback flag={flag}/>
	</ListItemSecondaryAction>
</ListItem>;

const MemoizedUrlRow = React.memo(UrlRow);

const SpiderDetail = inject('SpiderStore')(
	observer(({SpiderStore, match, ...props}) => {
		const [currentUrl, setCurrentUrl] = useState(null);
		const [statusPending, setStatusPending] = useState(false);

  		const [loadingAddFaq, setLoadingAddFaq] = useState(false);

	    useEffect(() => {
	        SpiderStore.getSiteById(match.params.siteId);
	    }, [SpiderStore, match]);


	    let currentSite = SpiderStore.sites.find(site => site.id === match.params.siteId.toString());


	    // useEffect(() => {
	    // 	console.log(currentSite, "currentSite");
	    //     if(currentSite && currentSite.status === "done"){
	    //     	currentSite.fetchURLs();
	    //     }
	    // }, [currentSite]);

	    useInterval(() => {
	    	if(currentSite && currentSite.status !== "done" && !statusPending){
	    		setStatusPending(true);
	    		currentSite.fetchStatus().then(data => {
	    			setStatusPending(false);
    			}).catch(err => {
	    			setStatusPending(false);
    			});
	    	}
		}, 2000);

		const handleSelectAllClick = (event) => {
			if (event.target.checked) {
				const newSelecteds = currentSite.filteredUrls.filter(url => url.metas).map((url) => url.id);
				currentSite.selectedUrls = newSelecteds;
				return;
			}
			currentSite.selectedUrls = [];
		};

		const handleClick = (id) => {
			const selectedIndex = currentSite.selectedUrls.indexOf(id);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(currentSite.selectedUrls, id);
			} else {
				newSelected = newSelected.concat(
					currentSite.selectedUrls.slice(0, selectedIndex),
					currentSite.selectedUrls.slice(selectedIndex + 1),
				);
			}

			currentSite.selectedUrls = newSelected;
		};

		const handleListItemClick = (id) => {
			setCurrentUrl(id ? currentSite.getURLById(id) : null);
		};

		const handleScoreFilter = (event, newScoresFilter)  => {
			currentSite.scoreFilters = newScoresFilter;
			currentSite.selectedUrls = []
		};

		const handleChangeOrder = event => {
    		currentSite.orderFilter = event.target.value;
  		};

  		const handleAddToFaq = () => {
  			if (!loadingAddFaq) {
		      	setLoadingAddFaq(true);
		      	currentSite.addSelectedUrlsToFaq().then(() => {
			      	Toast({message: "Synchonized with success", type: "success"});
			      	setLoadingAddFaq(false);
		      	}).catch(err => {Toast({message: err.message, type: "error"});
			      	setLoadingAddFaq(false);
		      	});
		  	}
  		};

		return <Layout>
			<PageWrapper>
				
				{
					!currentSite && <StyledLoader />	
				}

				{
					//currentSite && !currentSite.urlsLoaded && <StyledLoader />	
				}

				{
					currentSite && <>
						{
							currentSite.status !== "done" && <>
								<PageHeader 
									title="Scanning website"
									surtitle="Spider"
								/>
								<StyledCrawlingState>
									<div className="crawlingState">
										<StyledLinearProgress variant="indeterminate" />
									</div>
									<div className="crawlingStateInfos">
										<FontAwesomeIcon icon={currentSite.crawlingStateIcon} />
										<span className="crawlingStateTitle">{currentSite.crawlingStateLabel}</span>
										<hr/>
										{
											
											<p className="crawlingStateDetail">
												{currentSite.crawlingStateSentence}
											</p>
										}
									</div>
								</StyledCrawlingState>
							</>
						}
						{
							currentSite.status === "done" && <>
								<PageHeader 
									title="What i found"
									surtitle="Spider"
								/>

								<StyledButtonsList>
									<BannerButton
										onClick={() => {currentSite.filter = "all"; currentSite.selectedUrls = []}}
										className={currentSite.filter === "all" ? 'selected': ''}
									>
						        		<span className='customButton-title'>All URLs</span>
						        		<span className='customButton-total'>{currentSite.totalUrls}</span>
						        	</BannerButton>

									<BannerButton
										onClick={() => {currentSite.filter = "withMetas"; currentSite.selectedUrls = []}}
										className={currentSite.filter === "withMetas" ? 'selected': ''}
									>
						        		<span className='customButton-title'>URLs With Metadatas</span>
						        		<span className='customButton-total'>{currentSite.totalUrlsWithMeta}</span>
						        	</BannerButton>

									<BannerButton
										onClick={() => {currentSite.filter = "withoutMetas"; currentSite.selectedUrls = []}}
										className={currentSite.filter === "withoutMetas" ? 'selected': ''}
									>
						        		<span className='customButton-title'>URLs Without Metadatas</span>
						        		<span className='customButton-total'>{currentSite.totalUrlsWithoutMeta}</span>
						        	</BannerButton>
								</StyledButtonsList>

								<StyledListFilters>
									<div className="filtersLeft">
					              		<Checkbox
								            indeterminate={currentSite.selectedUrls.length > 0 && currentSite.selectedUrls.length < currentSite.filteredUrls.filter(url => url.metas).length}
								            checked={currentSite.filteredUrls.filter(url => url.metas).length > 0 && currentSite.selectedUrls.length === currentSite.filteredUrls.filter(url => url.metas).length}
								            onChange={handleSelectAllClick}
								            inputProps={{ 'aria-label': 'select all urls' }}
	        								color="primary"
					              		/>
					              		<Button
	        								color="primary"
	        								variant="contained"
	        								size="small"
	        								disabled={currentSite.selectedUrls.length === 0 || loadingAddFaq}
	        								onClick={e => handleAddToFaq()}
				              			>
					              			Synchronize with FAQs
					              			{loadingAddFaq && <StyledCircularProgress size={20} />}
					              		</Button>
									</div>

									<div className="filtersRight">

											<ToggleButtonGroup 
												size="small"
												onChange={handleScoreFilter}
												aria-label="score filter"
												value={currentSite.scoreFilters}
												className="scoreFilter"
											>
												<ToggleButton key={1} value="good">
													<StyledFeedback flag="good"/>
													<span>Good</span>
											    </ToggleButton>,
											    <ToggleButton key={2} value="warning">
											    	<StyledFeedback flag="warning"/>
											    	<span>Medium</span>
											    </ToggleButton>,
											    <ToggleButton key={3} value="bad">
											    	<StyledFeedback flag="bad"/>
											    	<span>Bad</span>
											    </ToggleButton>,
											</ToggleButtonGroup>


											<FormControl margin="dense" className="orderSelect" variant="outlined">
												<InputLabel id="urls-order-label">{"Order"}</InputLabel>
												<Select
													labelId="urls-order-label"
													id="urls-order"
													label="Order"
													autoWidth
													value={currentSite.orderFilter}
          											onChange={handleChangeOrder}
												>
													{
														Object.entries(URLsSort).map(([value, label]) => 
															<MenuItem key={`urlsort-${value}`} value={value}>{label}</MenuItem>
														)	
													}
												</Select>
											</FormControl>
											
											{
							              		/*<Button
			        								color="default"
			        								size="small"
	        										startIcon={<StyledFeedback flag="good"/>}
	        										onClick={() => handleScoreFilter("good")}
						              			>
							              			Good
							              		</Button>

							              		<Button
			        								color="default"
			        								size="small"
	        										startIcon={<StyledFeedback flag="warning"/>}
	        										onClick={() => handleScoreFilter("warning")}
						              			>
							              			Medium
							              		</Button>

							              		<Button
			        								color="default"
			        								size="small"
	        										startIcon={<StyledFeedback flag="bad"/>}
	        										onClick={() => handleScoreFilter("bad")}
						              			>
							              			Bad
							              		</Button>*/
											}
									</div>
								</StyledListFilters>

								<StyledURLsList>
									{
										currentSite.filteredUrls.length > 0 && currentSite.filteredUrls.map(url => {
											return <MemoizedUrlRow
												key={`url-${url.id}`}
												id={url.id}
												url={url.url}
												metas={url.metas}
												flag={url.feedback.flag}
												checked={currentSite.selectedUrls.indexOf(url.id) !== -1}
												onCheck={() => handleClick(url.id)}
												onClick={() => handleListItemClick(url.id)}
											/>
										})
									}
									{
										currentSite.filteredUrls.length === 0 && <li className="emptyList">
											<img src="//assets.reecall.co/illustration/svg/basket-empty.svg" alt="empty list" />
										</li>
									}
								</StyledURLsList>

								<StyledDrawer anchor="right" open={currentUrl !== null} onClose={() => setCurrentUrl(null)}>
						      		{ currentUrl && <URLDetail {...currentUrl} advices={currentUrl.advices} /> }
						   	 	</StyledDrawer>

							</>
						}
					</>
				}
							
			</PageWrapper>

		</Layout>
	})
);

export default withRouter(SpiderDetail);